@media screen and (min-width: 400px) and (max-width: 950px) {
    .dignityCard {
        width: 100%;
        height: 33vh;

    }

    .dignityCard__bg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background: rgba(0, 10, 6, 0.7); */
        /* backdrop-filter: blur(0.5px); */
    }

    .dignityCard__content {
        width: 100%;
        height: 100%;
        color: #fff;
        background: rgba(0, 10, 6, 0.7);
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
        padding-left: 33px;
        box-sizing: border-box;
    }

    .dignityCard__content > h4 {
        font-weight: 700;
        font-size: 28px;
        line-height: 110%;
        margin-bottom: 16px;
    }

    .dignityCard__content > p {
        width: 80vw;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
    }
}