/* ========================================================================
   Component: Animation
 ========================================================================== */
 [class*='uk-animation-'] {
    animation: 0.5s ease-out both;
  }
  /* Animations
   ========================================================================== */
  /*
   * Fade
   */
  .uk-animation-fade {
    animation-name: uk-fade;
    animation-duration: 0.8s;
    animation-timing-function: linear;
  }
  /*
   * Scale
   */
  .uk-animation-scale-up {
    animation-name: uk-fade, uk-scale-up;
  }
  .uk-animation-scale-down {
    animation-name: uk-fade, uk-scale-down;
  }
  /*
   * Slide
   */
  .uk-animation-slide-top {
    animation-name: uk-fade, uk-slide-top;
  }
  .uk-animation-slide-bottom {
    animation-name: uk-fade, uk-slide-bottom;
  }
  .uk-animation-slide-left {
    animation-name: uk-fade, uk-slide-left;
  }
  .uk-animation-slide-right {
    animation-name: uk-fade, uk-slide-right;
  }
  /*
   * Slide Small
   */
  .uk-animation-slide-top-small {
    animation-name: uk-fade, uk-slide-top-small;
  }
  .uk-animation-slide-bottom-small {
    animation-name: uk-fade, uk-slide-bottom-small;
  }
  .uk-animation-slide-left-small {
    animation-name: uk-fade, uk-slide-left-small;
  }
  .uk-animation-slide-right-small {
    animation-name: uk-fade, uk-slide-right-small;
  }
  /*
   * Slide Medium
   */
  .uk-animation-slide-top-medium {
    animation-name: uk-fade, uk-slide-top-medium;
  }
  .uk-animation-slide-bottom-medium {
    animation-name: uk-fade, uk-slide-bottom-medium;
  }
  .uk-animation-slide-left-medium {
    animation-name: uk-fade, uk-slide-left-medium;
  }
  .uk-animation-slide-right-medium {
    animation-name: uk-fade, uk-slide-right-medium;
  }
  /*
   * Kenburns
   */
  .uk-animation-kenburns {
    animation-name: uk-kenburns;
    animation-duration: 15s;
  }
  /*
   * Shake
   */
  .uk-animation-shake {
    animation-name: uk-shake;
  }
  /*
   * SVG Stroke
   * The `--uk-animation-stroke` custom property contains the longest path length.
   * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
   * All strokes are animated by the same pace and doesn't end simultaneously.
   * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
   */
  .uk-animation-stroke {
    animation-name: uk-stroke;
    animation-duration: 2s;
    stroke-dasharray: var(--uk-animation-stroke);
  }
  /* Direction modifier
   ========================================================================== */
  .uk-animation-reverse {
    animation-direction: reverse;
    animation-timing-function: ease-in;
  }
  /* Duration modifier
   ========================================================================== */
  .uk-animation-fast {
    animation-duration: 0.1s;
  }
  /* Toggle animation based on the State of the Parent Element
   ========================================================================== */
  .uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
    animation-name: none;
  }
  /* Keyframes used by animation classes
   ========================================================================== */
  /*
   * Fade
   */
  @keyframes uk-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /*
   * Scale
   */
  @keyframes uk-scale-up {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes uk-scale-down {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  /*
   * Slide
   */
  @keyframes uk-slide-top {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes uk-slide-bottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes uk-slide-left {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes uk-slide-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  /*
   * Slide Small
   */
  @keyframes uk-slide-top-small {
    0% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes uk-slide-bottom-small {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes uk-slide-left-small {
    0% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes uk-slide-right-small {
    0% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  /*
   * Slide Medium
   */
  @keyframes uk-slide-top-medium {
    0% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes uk-slide-bottom-medium {
    0% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes uk-slide-left-medium {
    0% {
      transform: translateX(-50px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes uk-slide-right-medium {
    0% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(0);
    }
  }
  /*
   * Kenburns
   */
  @keyframes uk-kenburns {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  /*
   * Shake
   */
  @keyframes uk-shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(-9px);
    }
    20% {
      transform: translateX(8px);
    }
    30% {
      transform: translateX(-7px);
    }
    40% {
      transform: translateX(6px);
    }
    50% {
      transform: translateX(-5px);
    }
    60% {
      transform: translateX(4px);
    }
    70% {
      transform: translateX(-3px);
    }
    80% {
      transform: translateX(2px);
    }
    90% {
      transform: translateX(-1px);
    }
  }
  /*
   * Stroke
   */
  @keyframes uk-stroke {
    0% {
      stroke-dashoffset: var(--uk-animation-stroke);
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  /*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
    animation-duration: 0.2s;
  }
  .uk-sticky.uk-animation-reverse {
    animation-duration: 0.2s;
  }
  /*
   * Placeholder
   * Make content clickable for sticky cover and reveal effects
   */
  .uk-sticky-placeholder {
    pointer-events: none;
  }
  /* ========================================================================
     Component: Off-canvas
   ========================================================================== */
  /*
   * 1. Hide by default
   * 2. Set position
   */
  .uk-offcanvas {
    /* 1 */
    display: none;
    /* 2 */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  /*
   * Flip modifier
   */
  .uk-offcanvas-flip .uk-offcanvas {
    right: 0;
    left: auto;
  }
  /* Bar
   ========================================================================== */
  /*
   * 1. Set position
   * 2. Size and style
   * 3. Allow scrolling
   */
  .uk-offcanvas-bar {
    /* 1 */
    position: absolute;
    top: 0;
    bottom: 0;
    left: -270px;
    /* 2 */
    box-sizing: border-box;
    width: 270px;
    padding: 20px 20px;
    background: #222;
    /* 3 */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  /* Phone landscape and bigger */
  @media (min-width: 640px) {
    .uk-offcanvas-bar {
      left: -350px;
      width: 350px;
      padding: 30px 30px;
    }
  }
  /* Flip modifier */
  .uk-offcanvas-flip .uk-offcanvas-bar {
    left: auto;
    right: -270px;
  }
  /* Tablet landscape and bigger */
  @media (min-width: 640px) {
    .uk-offcanvas-flip .uk-offcanvas-bar {
      right: -350px;
    }
  }
  /*
   * Open
   */
  .uk-open > .uk-offcanvas-bar {
    left: 0;
  }
  .uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
    left: auto;
    right: 0;
  }
  /*
   * Slide Animation (Used in slide and push mode)
   */
  .uk-offcanvas-bar-animation {
    transition: left 0.3s ease-out;
  }
  .uk-offcanvas-flip .uk-offcanvas-bar-animation {
    transition-property: right;
  }
  /*
   * Reveal Animation
   * 1. Set position
   * 2. Clip the bar
   * 3. Animation
   * 4. Reset position
   */
  .uk-offcanvas-reveal {
    /* 1 */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    /* 2 */
    width: 0;
    overflow: hidden;
    /* 3 */
    transition: width 0.3s ease-out;
  }
  .uk-offcanvas-reveal .uk-offcanvas-bar {
    /* 4 */
    left: 0;
  }
  .uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
    /* 4 */
    left: auto;
    right: 0;
  }
  .uk-open > .uk-offcanvas-reveal {
    width: 270px;
  }
  /* Tablet landscape and bigger */
  @media (min-width: 640px) {
    .uk-open > .uk-offcanvas-reveal {
      width: 350px;
    }
  }
  /*
   * Flip modifier
   */
  .uk-offcanvas-flip .uk-offcanvas-reveal {
    right: 0;
    left: auto;
  }
  /* Close
   * Adopts `uk-close`
   ========================================================================== */
  .uk-offcanvas-close {
    position: absolute;
    z-index: 1000;
    top: 5px;
    right: 5px;
    padding: 5px;
  }
  /* Tablet landscape and bigger */
  @media (min-width: 640px) {
    .uk-offcanvas-close {
      top: 10px;
      right: 10px;
    }
  }
  /*
   * Remove margin from adjacent element
   */
  .uk-offcanvas-close:first-child + * {
    margin-top: 0;
  }
  /* Overlay
   ========================================================================== */
  /*
   * Overlay the whole page. Needed for the `::before`
   * 1. Using `100vw` so no modification is needed when off-canvas is flipped
   * 2. Allow for closing with swipe gesture on devices with pointer events.
   */
  .uk-offcanvas-overlay {
    /* 1 */
    width: 100vw;
    /* 2 */
    touch-action: none;
  }
  /*
   * 1. Mask the whole page
   * 2. Fade-in transition
   */
  .uk-offcanvas-overlay::before {
    /* 1 */
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    /* 2 */
    opacity: 0;
    transition: opacity 0.15s linear;
  }
  .uk-offcanvas-overlay.uk-open::before {
    opacity: 1;
  }
  /* Prevent scrolling
   ========================================================================== */
  /*
   * Prevent horizontal scrollbar when the content is slide-out
   * Has to be on the `html` element too to make it work on the `body`
   */
  .uk-offcanvas-page,
  .uk-offcanvas-container {
    overflow-x: hidden;
  }
  /* Container
   ========================================================================== */
  /*
   * Prepare slide-out animation (Used in reveal and push mode)
   * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
   * lose their fixed state and behaves like `absolute` within a transformed container
   * 1. Provide a fixed width and prevent shrinking
   */
  .uk-offcanvas-container {
    position: relative;
    left: 0;
    transition: left 0.3s ease-out;
    /* 1 */
    box-sizing: border-box;
    width: 100%;
  }
  /*
   * Activate slide-out animation
   */
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 270px;
  }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -270px;
  }
  /* Tablet landscape and bigger */
  @media (min-width: 640px) {
    :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
      left: 350px;
    }
    .uk-offcanvas-flip.uk-offcanvas-container-animation {
      left: -350px;
    }
  }