@media screen and (min-width: 320px) and (max-width: 399px) {
  .contactUs {
    display: flex;
    padding: 20px 30px 53px;
    row-gap: 37px;
    box-sizing: border-box;
    flex-direction: column;
  }
  .contactUs__contactZone {
    width: 100%;
  }
  .contactUs__formContainer {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding-top: 30px;
  }
  .contactUs__input {
    width: 100%;
  }
  .contactUs__input:last-of-type {
    margin-bottom: 5px;
  }
  .contactUs__contacts {
    background: url("../../../Media/ArrowRight.svg") top left no-repeat;
    background-size: 74px;
    width: 230px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 84px;
  }
}
