.preloader {
  position: fixed;
  inset: 0;
  display: flex;
  background-color: white;
  z-index: 3;
}
.preloader__rectangles {
  margin: auto;
  display: flex;
}
.preloader__rectangle {
  width: 92px;
  height: 221px;
  flex-shrink: 0;
  background-color: #e8edf3;
}
.preloader__pink {
  transform: rotate(15deg);
  animation: preloaderPink 3s ease 0s infinite normal forwards;
}
.preloader__red {
  transform: rotate(-15deg) translate(23px, -15px);
  animation: preloaderRed 3s ease 0s infinite normal forwards;

}
.preloader__violet {
  transform: rotate(15deg);
  animation: preloaderViolet 3s ease 0s infinite normal forwards;

}

@keyframes preloaderPink {
  0% {
    background-color: #e8edf3;
  }
  25% {
    background-color: #ffc7cb;

  }
  50% {
    background-color: #e8edf3;

  }
  75% {
    background-color: #e8edf3;

  }
  100% {
    background-color: #ffc7cb;

  }
}

@keyframes preloaderRed {
  0% {
    background-color: #e8edf3;

  }
  25% {
    background-color: #e8edf3;

  }
  50% {
    background-color: #ff4324;

  }
  75% {
    background-color: #e8edf3;

  }
  100% {
    background-color: #ff4324;

  }
}

@keyframes preloaderViolet {
  0% {
    background-color: #e8edf3;

  }
  25% {
    background-color: #e8edf3;

  }
  50% {
    background-color: #e8edf3;

  }
  75% {
    background-color: #512cb7;

  }
  100% {
    background-color: #512cb7;

  }
}
