@media screen and (min-width: 400px) and (max-width: 599px) {
  .projectBlock {
    min-width: 285px;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    width: 75vw;
    height: 100%;
  }

  .projectBlock_hovered {
    padding: 23px 20px 27px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #e8edf3;
    color: #18191f;
    box-sizing: border-box;
  }
  .projectBlock__title_hovered {
    font-size: 23px;
    font-weight: 700;
    font-feature-settings: "salt" on, "liga" off;
  }
  .projectBlock__text_hovered {
    margin: 20px 0 23px;
    font-size: 15px !important;
  }
  .projectBlock__button_hovered {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    width: 133px;
    background-size: 41px;
    font-size: 14px;
    line-height: 120%;
    box-sizing: border-box;
  }
}
