@media screen and (min-width: 1900px) {
    .dignityCard {
        min-width: 795px;
        height: 420px;
        background-color: #E8EDF3;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
    }

    .dignityCard__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        row-gap: 31px;
        max-width: 645px;
    }

    .dignityCard h4 {
        font-weight: 700;
        font-size: 43px;
        line-height: 130%;
    }

    .dignityCard p {
        font-size: 33px;
        line-height: 130%;
        font-weight: 400;
    }
}