@media screen and (min-width: 1900px) {
  .project__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
  }
  .project__titleContainer {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 1038px;
    padding: 113px 100px 38px;
  }
  .project__section {
    font-size: 26px;
  }
  .project__tagArea {
    display: flex;
    column-gap: 5px;
    transform: translateX(-16px);
    flex-wrap: wrap;
  }
  .project__tag {
    color: #949494;
    font-size: 26px;
    line-height: 130%;
    padding: 8px 16px 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .project__tag:hover {
    background: #e8edf3;
    border-radius: 7px;
    color: #323635;
  }
  .project__content {
    padding: 115px 100px 0;
    box-sizing: border-box;
    display: flex;
    column-gap: 183px;
  }
  .project__mainText {
    margin-bottom: 137px;
    display: flex;
    flex-direction: column;
    row-gap: 55px;
    max-width: calc(100vw - 516px - 183px - 200px);
  }
  .project__subtitle {
    font-size: 50px;
    box-sizing: border-box;
    /* padding-left: 60px; */
  }
  .project__subtitleIcon {
    width: 75px;
    height: 74px;
  }
  .project__text {
    font-size: 33px;
    line-height: 150%;
    color: #000000;
  }
  .projectInfo__container {
    width: 516px;
    flex-shrink: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    margin-bottom: 136px;
    height: fit-content;
  }
  .projectInfo__title {
    padding: 19px 0 30px;
    font-size: 33px;
    line-height: 120%;
    color: #323635;
  }
  .project__recommedations {
    display: flex;
    flex-direction: column;
    row-gap: 62px;
    padding-top: 12px;
  }
  .project__recommedations > h2 {
    padding: 0 100px;
  }
  .project__recommendationsImages {
    display: flex;
    column-gap: 4px;
  }
  .project__getbackBtnArrow {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") left center /
      contain no-repeat;
    width: 55px;
    transform: rotate(180deg);
  }
  .project__getbackBtnContainer {
    display: flex;
    cursor: pointer;
    padding: 0 100px 71px;
  }
  .project__getbackBtn {
    padding-left: 20px;
    font-size: 26px;
    line-height: 120%;
    color: #454545;
  }
  .project__bigImage {
    width: 100%;
    object-fit: cover;
    height: 960px;
    padding: 0 100px 136px;
    box-sizing: border-box;
  }
  .project__smallImage {
    width: 100%;
    object-fit: cover;
    height: 613px;
    padding: 62px 0 0;
  }
  .project__videoContainer {
    width: 100%;
    height: 613px;
    position: relative;
    background-color: #949494;
    transition: all ease-in-out 0.4s 0s;
    margin-top: 62px;
  }
  .project__videoContainer_wide {
    width: calc(100vw - 200px);
    margin-top: 62px;
    /* margin: 0 100px; */
    height: 960px;
    z-index: 2;
    background-color: #949494;
    position: relative;
    /* left: -473px; */
    /* right: 0; */
  }
  .videoOpened {
    position: relative;
    left: -699px;
    height: 960px;
    transition: all ease-in 0.4s 0s;
  }
  .videoClosed {
    position: relative;
    left: 0px;
    height: fit-content;
    transition: all ease-in 0.4s 0s;
  }
  .project__videoPlayBtn {
    background: url("../../../Media/PlayBtn.svg") center / contain no-repeat;
    width: 71px;
    height: 71px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 3;
  }
  .project__videoStopBtn {
    background: url("../../../Media/ArrowRigthWhite.svg") center / contain
      no-repeat;
    transform: rotate(-45deg);
    width: 75px;
    height: 55px;
    position: absolute;
    z-index: 3;
    bottom: 15px;
    left: 18px;
    cursor: pointer;
    transition: all ease-in-out 0.2s 0s;
    opacity: 0;
    z-index: 3;
  }
  .project__videoStopBtn:hover {
    opacity: 1;
  }
  .notVisible {
    opacity: 0;
    visibility: none;
    display: none;
  }
  .infographics__container {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 516px;
  }
  .infographics__block {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .infographics__container > div:last-of-type {
    margin-top: 24px;
  }
  .infographics__number {
    font-size: 77px;
    line-height: 90%;
  }
  .infographics__text {
    font-size: 23px;
    width: 290px;
  }
  .coverSlider__container {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    max-height: 1078px;
    overflow: hidden;
    height: 100%;
  }
  .slider__btn-prev,
  .slider__btn-next {
    position: absolute;
    /* background-color: brown; */
    width: 200px;
    height: 200px;
    z-index: 2;
  }
  .slider__btn-prev {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: url("../../../Media/SliderArrowLeft.svg") center / 30px
      no-repeat;
  }
  .slider__btn-next {
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(180deg);
    background: url("../../../Media/SliderArrowLeft.svg") center / 30px
      no-repeat;
  }
  .slick-track {
    display: flex;
    /* color: #ffffff; */
  }
  .slick-track > div > div {
    height: 100%;
  }
}
