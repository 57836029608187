@media screen and (min-width: 600px) and (max-width: 949px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #18191F;
    color: #949494;
    width: 100%;
    padding: 68px 64px 79px;
    box-sizing: border-box;
    font-size: 19px;
  }
  .footer__links {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .footer__tabs {
    color: white;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }
  .footer__socialMedia {
    display: flex;
    column-gap: 20px;
    margin: 65px 0 35px;
    height: 37px;
    justify-content: center;
  }
  .footer__copyright {
    font-size: 20px;
    padding-top: 20px;
  }
}
