@media screen and (min-width: 600px) and (max-width: 949px) {
  .showreel {
    position: relative;
    width: 100%;
    height: 503px;
    display: flex;
  }
  .showreel__cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .showreel__content {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0px 87px 60px 50px;
    box-sizing: border-box;
    align-items: center;
  }
  .showreel__text {
    font-size: 64px;
    line-height: 90%;
    font-feature-settings: "salt" on, "liga" off;
    color: #ffffff;
    max-width: 40%;
  }
  .showreel__playBtn {
    background: url("../../../Media/PlayBtn.svg") center no-repeat;
    width: 67px;
    height: 67px;
    background-size: contain;
    cursor: pointer;
  }
  .showreel__video {
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    height: 100%;
    border: none;
    width: 100%;
    background-color: #18191F;
    z-index: 2;
  }
}
