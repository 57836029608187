@media screen and (min-width: 950px) and (max-width: 1479px) {
  .needs {
    display: flex;
    padding: 93px 21px 112px 50px;
    /* justify-content: space-between; */
  }
  .needs__content-container {
    width: 77%;
    padding-right: 31px;
  }
  .needs__title {
    font-weight: 700;
  }
  .needs__text {
    padding: 31px 0 62px;
    max-width: 660px;
  }
  .needs__needs-container{
    padding: 33px 0 0;
  }
  .needs__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 440px;
  }
  .needs__info-accent {
    font-size: 64px;
    font-weight: 700;
    line-height: 91.5%;
    font-feature-settings: "salt" on, "liga" off;
    padding-bottom: 20px;
  }
  .needs__info-block {
    display: flex;
    flex-direction: column;
  }
}
