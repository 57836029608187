@import url("./Showreel_1920.css");
@import url("./Showreel_1000.css");
@import url("./Showreel_700.css");
@import url("./Showreel_428_375.css");




@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .showreel {
    position: relative;
    width: 100%;
    display: flex;
  }
  .showreel__cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .showreel__content {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 90px 140px 90px 102px;
    box-sizing: border-box;
    align-items: center;
  }
  .showreel__text {
    font-size: 100px;
    line-height: 90%;
    font-feature-settings: "salt" on, "liga" off;
    color: #ffffff;
    max-width: 30%;
  }
  .showreel__playBtn {
    background: url("../../../Media/PlayBtn.svg") center no-repeat;
    width: 97px;
    height: 97px;
    cursor: pointer;
  }
  .showreel__video {
    top: -1px;
    border: none;
    background-color: #fff;
    z-index: 2;
    display: none;
  }
}
