@import url("./PodcastInfo_mobile.css");
@media screen and (min-width: 950px) {

.podcastInfo {
  padding: 87px 0;
  display: flex;
  column-gap: 56px;
  justify-content: space-between;
  border-bottom: 2px solid #E8EDF3;
}
.podcastInfo__textContainer {
  display: flex;
  flex-direction: column;
  row-gap: 55px;
}
.podcastInfo__text{
padding-right: 70px;
line-height: 150%;
}
.podcastInfo__mediaBlock {
  padding: 33px 35px 25px 37px;
  box-sizing: border-box;
  background: #e8edf3;
  border-radius: 6px;
  min-width: 334px;
}
.podcastInfo__cover {
  width: 150px;
  height: 150px;
  background: url('../../../Media/PodcastGeneralCover.png') center no-repeat;
}
.podcastInfo__mediaTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  width: 212px;
  padding: 24px 0 10px;
}
.podcastInfo__mediaLinks {
  display: flex;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.25);
  column-gap: 13px;
  row-gap: 4px;
  font-size: 16px;
  line-height: 130%;
}
.podcastInfo__mediaLinks > a:hover {
  color: #454545;
}
}