@import url("./PodcastPopup_mobile.css");

@media screen and (min-width: 950px) {

.podcastPopup__bubble {
  position: absolute;
  background: url("../../../Media/PodcastPopupBubble.svg") center no-repeat;
  width: 340px;
  height: 130px;
  padding: 20px 40px 50px;
  box-sizing: border-box;
  top: -130px;
  right: -50px;
  animation: opacity 0.3s ease 0s 1 forwards;

}
.podcastPopup__links {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 4px;
  justify-content: center;
}
.podcastPopup__link {
  font-size: 15px;
  line-height: 130%;
  text-align: center;
  color: #454545;
}
.podcastPopup__link:hover {
  color: #ff4324;
}
@keyframes opacity {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
}