@media screen and (min-width: 950px) and (max-width: 1479px) {
  .time__toListen {
    background: url("../../../Media/TimeToListenDarkIcon.svg") left 3px
      no-repeat;
    padding-left: 22px;
    margin: 3px 0 12px 29px;
    font-size: 15px;
    line-height: 120%;
    color: rgba(148, 148, 148, 1);
  }
  .time__toRead {
    background: url("../../../Media/TimeToReadDarkIcon.svg") left top no-repeat;
    padding-left: 22px;
    margin: 3px 0 12px 29px;
    font-size: 15px;
    line-height: 120%;
    color: rgba(148, 148, 148, 1);
  }
  .time__toListen.timelight {
    background: url("../../../Media/TimeToListenLightIcon.svg") left 3px
      no-repeat;
    color: #e8edf3;
  }
  .time__toRead.timelight {
    background: url("../../../Media/TimeToReadLightIcon.svg") left top no-repeat;
    color: #e8edf3;
  }
}
