@import url("./Dignity_1920.css");
@import url("./Dignity_1000.css");
@import url("./Dignity_700.css");
@import url("./Dignity_375-428.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .dignity {
    padding-top: 112px;
    padding-bottom: 50px;
  }

  .dignity__content {
    height: 859px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .dignity-bg {
    max-width: 100%;
    height: 703px;
    background: url("../../../Media/DignityBg.jpg") center / cover no-repeat;
    top: 0;
    z-index: 0;
    position: absolute;
  }

  .dignity__cards-block {
    display: flex;
    padding: 0 88px;
    z-index: 1;
    overflow: auto;
    top: 544px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    column-gap: 37px;
    height: fit-content;
  }

  .dignity__cards-block::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
