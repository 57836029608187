@import url("./ServiceDescription_1920.css");
@import url("./ServiceDescription_1000.css");
@import url("./ServiceDescription_mobile.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .serviceDescription {
    padding: 14px 100px 38px;
    box-sizing: border-box;
    display: flex;
    column-gap: 37px;
  }
  .serviceDescription__description {
    background: #e8edf3;
    border-radius: 20px;
    padding: 95px 83px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    box-sizing: border-box;
    width: calc(100% - 388px);
  }
  .serviceDescription__description > h3 {
    color: #a1a1a1;
  }
  .serviceDescription__description > p {
    font-size: 23px;
    line-height: 140%;
  }
  .serviceDescription__decoration {
    width: 351px;
    height: auto;
    border-radius: 20px;
    flex-shrink: 0;
    min-height: 451px;
    object-fit: cover;
  }
  .serviceDescription__animation_first {
    animation: slideBottomServices .6s ease 0s 1 normal forwards;
  }
  .serviceDescription__animation_second {
    animation: slideBottomServices .6s ease .2s 1 normal forwards;
  }
  @keyframes slideBottomServices {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
