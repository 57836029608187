.mobileMenu__background {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  height: calc(100vh - 70px);
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.58);
}
.mobileMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 57px;
  background-color: white;
  transform: translateY(-120%);
  transition: all ease 1s;
}
.mobileMenu.opened {
  transform: translateY(0);
}
.mobileMenu__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 66px 0 56px;
  row-gap: 30px;
}
.mobileMenu__link {
  font-size: 25px;
  line-height: 20px;
  font-feature-settings: "salt" on, "liga" off;
  color: #18191f;
}
.mobileMenu__link.activated {
  font-weight: 700;
}

