@import url("./MoreBtnWithArrow_1920.css");

.moreBtnWithArrow{
    background: url('../../../Media/ArrowRigthDarkRoundCorners.svg') right center / 41px no-repeat;
    margin-top: auto;
    width: 170px;
    box-sizing: border-box;
    font-size: 20px;
    
}
.grayBtnWithArrow{
    color:#454545;
    filter: invert(66%) sepia(0%) saturate(445%) hue-rotate(204deg) brightness(90%) contrast(86%);
} 
.darkBtnWithArrow{
    color: #454545;
    filter: invert(27%) sepia(0%) saturate(134%) hue-rotate(155deg) brightness(94%) contrast(93%);
} 
.whiteBtnWithArrow{
    color: #FFFFFF;
    background: url('../../../Media/ArrowRigthWhiteRoundCorners.svg') right center / 41px no-repeat;
} 