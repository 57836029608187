@import url("./WorkWithUs_1920.css");
@import url("./WorkWithUs_1000.css");
@import url("./WorkWithUs_700.css");
@import url("./WorkWithUs_428.css");
@import url("./WorkWithUs_375.css");


@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .workWithUs {
    display: flex;
  }
  .workWithUs__content {
    padding: 92px 100px 96px;
  }
  .workWithUs__title {
    padding: 40px 0 59px;
    font-size: 58px;
  }
  .workWithUs__btnsContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .workWithUs__arrow {
    background: url("../../../Media/ArrowRight.svg") center no-repeat;
    width: 74px;
    height: 54px;
    margin-right: 33px;
  }
  .workWithUs__image {
    background: url("../../../Media/WorkWithUsPhoto.png") right no-repeat;
    width: 66%;
    background-size: contain;
  }
}
