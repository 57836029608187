@media screen and (min-width: 1900px) {
    .coolCase {
        height: 625px;
        padding: 63px 198px 143px 102px;
    }

    .coolCase__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .coolCase__text {
        font-weight: 400;
        font-size: 77px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .coolCase__arrow {
        background: url('../../../Media/ArrowRight.svg') center no-repeat;
        background-size: contain;
        width: 74px;
        height: 54px;
        margin-top: 53px;
    }

    .coolCase__rectangles {
        width: 800px;
        height: 625px;
        display: flex;
        position: relative;
    }

    .coolCase__rectangle {
        width: 224px;
        height: 535px;
        position: absolute;
    }


    .coolCase__rectangle_pink {
        background: #FFC7CB;
        transform: rotate(15deg);
        right: 443px;
        margin-top: 50px;
    }

    .coolCase__rectangle_red {
        background: #FF4324;
        transform: rotate(-15deg);
        right: 186px;
    }

    .coolCase__rectangle_blue {
        background: #512CB7;
        transform: rotate(15deg);
        margin-top: 50px;
        right: 0;
    }
}