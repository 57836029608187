@import url("./TopBanner_1920.css");
@import url("./TopBanner_1000.css");
@import url("./TopBanner_700.css");
@import url("./TopBanner_428.css");
@import url("./TopBanner_375.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .topBanner__section {
    user-select: none;
  }
  .topBanner {
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    height: calc(90vh - 88px);
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .topBanner__container {
    width: 65%;
    display: flex;
    margin: auto 0;
    flex-direction: column;
  }
  .topBanner__text {
    z-index: 2;
    margin-bottom: 5%;
    max-width: 890px;
  }
  .topBanner__rectangles {
    position: absolute;
    right: 8.7%;
    top: 50%;
    display: flex;
    transform: translateY(-50%);
  }
  .topBanner__rectangle {
    width: 168.65px;
    height: 402.71px;
    background-color: #e8edf3;
    transition: background-color ease-in-out 0.6s;
  }
  .rightRectangle {
    transform: rotate(7deg) translate(6px, 5px);
  }
  .centerRectangle {
    transform: rotate(-9deg) translate(8px, -30px);
  }
  .leftRectangle {
    transform: rotate(7deg) translate(3px, 6px);
  }
  .violet {
    background-color: #512cb7;
  }
  .orange {
    background-color: #ff4324;
  }
  .pink {
    background-color: #ffc7cb;
  }

  .topBanner__btn-block {
    background: url("../../../Media/ArrowRight.svg") center left no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 345px;
    height: 54px;
    background-size: contain;
  }
  .topBanner__clients {
    width: 100%;
    height: 10vh;
    column-gap: 67px;
    overflow: auto;
    background-color: #454545;
    display: flex;
    align-items: center;
    padding: 0 100px;
    box-sizing: border-box;
    transition: opacity ease-in-out 0.6s;
    cursor: none;
    user-select: none;
  }
  .cursor {
    transition: opacity ease-in-out 0.6s 0s;
    opacity: 0;
  }
  .cursor_active {
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 50%;
    z-index: 3;
    position: absolute;
    width: 40px;
    height: 40px;
    transform: translate(-30%, -50%);
    background: url("../../../Media/CursorScroll.svg") center no-repeat;
    pointer-events: "none";
    cursor: none;
  }
  .topBanner__clients::-webkit-scrollbar {
    display: none;
  }
  .topBanner__clients.hidden {
    opacity: 0;
  }
  .topBanner__clientLogo {
    height: 100%;
  }
}
