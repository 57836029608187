@media screen and (min-width: 320px) and (max-width: 949px) {
    .coolCase {
        padding: 16px 0px 0px 40px;
        overflow: hidden;
        max-height: 430px;
    }
    
    .coolCase__content {
        display: flex;
        flex-direction: column;
    }
    
    .coolCase__text {
        font-weight: 400;
        font-size: 32px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .coolCase__arrow {
        background: url('../../../Media/ArrowRight.svg') center no-repeat;
        background-size: contain;
        width: 74px;
        height: 54px;
        margin-top: 24px;
    }
    
    .coolCase__rectangles {
        width: 378px;
        height: 330px;
        display: flex;
        position: relative;
        /* padding-left: 60px; */
        right: -55px;
    align-self: flex-end;
    }
    
    .coolCase__rectangle {
        width: 126px;
        height: 301px;
        position: absolute;
        background: #E8EDF3;
        
    }

    .coolCase__rectangle_pink {
        background: #FFC7CB;
        transform: rotate(15deg);
        right: 248px;
        margin-top: 37px;
        /* animation: coloringPink .3 ease 1s 1 forwards; */
        transition: background-color 1s ease .3s;
    }
    
    .coolCase__rectangle_red {
        background: #FF4324;
        transform: rotate(-15deg);
        right: 104px;
        /* animation: coloringRed .3 ease 1.3s 1 forwards; */

    }
    
    .coolCase__rectangle_blue {
        background: #512CB7;
        transform: rotate(15deg);
        margin-top: 37px;
        right: 0;
        /* animation: coloringBlue .3 ease 2.6s 1 forwards; */

    }
}
@keyframes coloringPink {
    0% {

        background: #E8EDF3;
    }
    100% {

        background: #FFC7CB;

    }
  }
  @keyframes coloringRed {
    0% {

        background: #E8EDF3;
    }
    100% {
        background: #FF4324;


    }
  }
  @keyframes coloringBlue {
    0% {

        background: #E8EDF3;
    }
    100% {

        background: #512CB7;

    }
  }