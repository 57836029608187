@media screen and (min-width: 320px) and (max-width: 399px) {
  .portfolio {
    padding-bottom: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  .portfolio__header {
    padding: 46px 0px 37px 30px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
  }
  .portfolio__container {
    /* position: absolute; */
    width: 100%;
    display: flex;
    overflow: scroll;
    column-gap: 30px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  .portfolio__container > .projectBlock {
    height: auto;
    flex-shrink: 0;
  }
  .portfolio__container::-webkit-scrollbar {
    display: none;
  }
  .portfolio__project {
    min-width: 285px;
    object-fit: cover;
    display: flex;
    flex-direction: column;
  }
  .portfolio__emptyProject {
    flex-direction: column;
    position: relative;
    display: flex;
    padding: 41px 42px 19px 21px;
    box-sizing: border-box;
    background-color: #512cb7;
    font-size: 25px;
    line-height: 100%;
    color: #ffffff;
    height: auto;
    width: calc(100vw - 80px);
    flex-shrink: 0;
  }
  .portfolio__emptyProjectRectangle {
    background: url("../../../Media/EmptyProjectDecoration.svg") center bottom /
      contain no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .portfolio__emptyProjectContent {
    background: url("../../../Media/ArrowRigthWhite.svg") right bottom no-repeat;
    padding-right: 20%;
    background-size: 74px;
    height: 160px;
  }
}
