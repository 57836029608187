@media screen and (min-width: 320px) and (max-width: 399px) {
  .headerMobile {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .headerMobile__logo {
    width: 126px;
  }
  .headerMobile__menuBtn {
    display: flex;
    width: 46px;
    justify-content: space-between;
  }
  .headerMobile__menuDot {
    background: #18191f;
    transform: matrix(1, 0.24, -0.09, 0.97, 0, 0);
    width: 10.17px;
    height: 10.35px;
    transition: all ease 0.5s;
  }
  .menuOpened {
    background: #512cb7;
    transform: matrix(-1, 0.24, 0.09, 0.97, 0, 0);
  }
  .mobileMenu__language {
    color: #949494;
    font-size: 18px;
    text-transform: uppercase;
    padding-right: 36px;
  }
}
