@media screen and (min-width: 950px) and (max-width: 1480px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232520;
    color: #949494;
    width: 100%;
    padding: 129px 146px 105px;
    box-sizing: border-box;
  }
  .footer__links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 19px;
  }
  .footer__tabs {
    color: white;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 30px;
  }
  .footer__socialMedia {
    display: flex;
    column-gap: 20px;
    margin: 56px 0 0px;
    justify-content: center;
  }
  .footer__copyright {
    font-size: 20px;
  }
}
