@import url("./SpecialistPopup_mobile.css");

@media screen and (min-width: 1150px) {
  .specialistPopup {
    background-color: rgba(0, 0, 0, 0.55);
    position: fixed;
    inset: 0;
    overflow-y: scroll;
    z-index: 2;
    opacity: 0;
    animation: openPopup 1s ease 0s 1 normal forwards;
  }
  .specialistPopup__content {
    display: flex;
    column-gap: 48px;
    box-sizing: border-box;
    padding: 87px 80px 95px;
    background: white;
    width: calc(100% - 160px);
    margin: auto;
    transform: translateY(110px);
  }
  .specialistPopup__closeBtn {
    position: absolute;
    background: url("../../../../Media/CloseBtnCrossBlack.svg") center / contain
      no-repeat;
    width: 24px;
    height: 24px;
    top: 164px;
    right: 161px;
    z-index: 2;
    cursor: pointer;
  }
  .specialistPopup__photoContainer {
    width: 375px;
    height: 375px;
    background-color: #e8edf3;
    display: flex;
    align-items: flex-end;
    margin-bottom: 34px;
  }
  .specialistPopup__photoContainer > img {
    object-fit: contain;
    width: 100%;
    max-height: 100%;
  }
  .specialistPopup__name {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 11px;
  }
  .specialistPopup__jobTitle {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 84px;
  }
  .specialistPopup__connection {
    background: url("../../../../Media/ArrowRight.svg") left center / contain
      no-repeat;
    padding-left: 115px;
  }
  .specialistPopup__paragraphs {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    margin-bottom: 73px;
    padding-right: 50px;
  }
  .specialistPopup__paragraphs > p {
    font-size: 25px;
    line-height: 150%;
  }
  .specialistPopup__mainServices > span {
    font-size: 20px;
  }
  .specialistPopup__services {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    margin-top: 45px;
  }
  @keyframes openPopup {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    40% {
      transform: translateY(0);
    }
    100% {
      opacity: 1;
    }
  }
}
