@media screen and (min-width: 320px) and (max-width: 949px) {
  .serviceDescription {
    padding: 46px 40px 0;
    box-sizing: border-box;
   
  }
  .serviceDescription__description{
    display: flex;
    row-gap: 19px;
    flex-direction: column;
  }
  .serviceDescription__description > p {
    font-size: 17px;
    line-height: 150%;
  }
  .serviceDescription__description > h3 {
    font-size: 16px;
  }
}
