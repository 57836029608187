@media screen and (min-width: 950px) and (max-width: 1479px) {
  .insights {
    padding: 50px 50px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .insights__topPart {
    width: 100%;
  }
  .insights__titles {
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    max-width: 90%;
  }
  .insights__mainInsight-container {
    position: relative;
    object-fit: cover;
    width: 100%;
    cursor: pointer;
    max-height: 563px;
    align-items: center;
    display: flex;
    overflow: hidden;
    margin: 57px 0 38px;
  }
  .insights__section-container {
    display: flex;
    column-gap: 22px;
    padding-bottom: 60px;
    border-bottom: rgba(232, 237, 243, 1) solid 2px;
    margin-bottom: 3px;
  }
  .insights__moreBtn-container {
    padding: 70px 0 0px;
  }

}
