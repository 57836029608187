@media screen and (min-width: 320px) and (max-width: 949px) {
  .scroll__parentContainer {
    height: 100vh;
    overscroll-behavior-y: none;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    position: relative;
  }
  .scroll__container::-webkit-scrollbar,
  body::-webkit-scrollbar, .AboutUs::-webkit-scrollbar {
    display: none;
  }
  .scroll__child {
    background-color: white;
    width: 100%;
  }
  .child__container {
    position: absolute;
    inset: 0;
    height: 100vh;
    width: 100%;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
  }

  .hidden {
    opacity: 0;
  }
  .current {
    opacity: 1;
    z-index: 5;
  }
  .before {
    opacity: 1;
    z-index: 4;
  }
  
  /* animation block */
  .scrollDown.current {
    animation: slidingUp 1s ease 0s 1 forwards;
  }
  .scrollUp.current {
    animation: slidingDown 0.7s ease 0s 1 forwards;
    /* z-index: 3; */
  }
  .scrollDown.before {
    animation: slidingFromDown 1s ease 0s 1 forwards;
    z-index: 3;
  }
  .scrollUp.before {
    animation: slidingFromTop .7s ease 0s 1 forwards;
    z-index: 0;
  }

  @keyframes slidingUp {
    0% {
      transform: translateY(60%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slidingDown {
    0% {
    position: absolute;
    z-index: 3;

      transform: translateY(-100%);
    }

    100% {
    position: absolute;
    z-index: 3;

      transform: translateY(0);
    }
  }
  @keyframes slidingFromTop {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(60%);
    }
  }
  @keyframes slidingFromDown {
    0% {
      transform: translateY(0);
      /* z-index: 3; */
    position: absolute;

    }
    100% {
      transform: translateY(-100%);
      /* z-index: 3; */
    position: absolute;

    }
  }
}
