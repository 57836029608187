@media screen and (min-width: 950px) and (max-width: 1479px) {

.ourServices{
    padding: 59px 50px;
    box-sizing: border-box;
    display: flex;
    row-gap: 52px;
    flex-direction: column;
}
.ourServices__titles{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
}
.ourServices__titles > h2{
    max-width: 800px;
}
.ourServices__services-container{
    padding-top: 33px;
    width: 100%;
}
}