@media screen and (min-width: 1900px) {
  .topBanner__section{
    user-select: none;
  }
  .topBanner {
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    height: calc(90vh - 100px);
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .topBanner__container {
    width: 1100px;
    display: flex;
    margin: auto 0;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .topBanner__text {
    z-index: 2;
    margin-bottom: 60px;
  }
  .topBanner__rectangles {
    position: absolute;
    right: 6.9%;
    top: 50%;
    display: flex;
    transform: translateY(-54%);
  }
  .topBanner__rectangle {
    width: 225px;
    height: 537px;
    background-color: #e8edf3;
    transition: background-color ease-in-out 0.6s;
  }
  .rightRectangle {
    transform: rotate(15deg) translate(-2px, 17px);
  }
  .centerRectangle {
    transform: rotate(-9deg) translate(8px, -40px);
  }
  .leftRectangle {
    transform: rotate(7deg) translate(3px, 6px);
  }
  .violet {
    background-color: #512cb7;
  }
  .orange {
    background-color: #ff4324;
  }
  .pink {
    background-color: #ffc7cb;
  }
  .topBanner__btn-block {
    background: url("../../../Media/ArrowRight.svg") center left no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 424px;
    height: 65px;
    background-size: 89px;
  }
  .topBanner__clients {
    width: 100%;
    height: 10vh;
    column-gap: 93px;
    overflow: auto;
    background-color: #454545;
    display: flex;
    align-items: center;
    padding: 0 100px;
    box-sizing: border-box;
    transition: opacity ease-in-out 0.6s;
    cursor: none;
    justify-content: space-between;
  }
  .cursor {
    transition: opacity ease-in-out .6s;
    opacity: 0;
  }
  .cursor_active {
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 50%;
    z-index: 3;
    position: absolute;
    width: 40px;
    height: 40px;
    transform: translate(-30%, -50%);
    background: url("../../../Media/CursorScroll.svg") center no-repeat;
    pointer-events: 'none';
    cursor: none;
  }
  .topBanner__clients::-webkit-scrollbar {
    display: none;
  }
  .topBanner__clients.hidden {
    opacity: 0;
  }
  .topBanner__clientLogo{
    height: 100%;
  }
}
