@import url("./CoolCase_1920.css");
@import url("./CoolCase_1000.css");
@import url("./CoolCase_mobile.css");


@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .coolCase {
    padding: 63px 134px 110px 87px;
  }

  .coolCase__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .coolCase__text {
    font-weight: 400;
    font-size: 58px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .coolCase__arrow {
    background: url('../../../Media/ArrowRight.svg') center no-repeat;
    background-size: contain;
    width: 74px;
    height: 54px;
    margin-top: 40px;
  }

  .coolCase__rectangles {
    width: 600px;
    height: 470px;
    display: flex;
    position: relative;
  }

  .coolCase__rectangle {
    width: 168px;
    height: 402px;
    position: absolute;
  }

  .coolCase__rectangle_pink {
    background: #FFC7CB;
    transform: rotate(15deg);
    right: 331px;
    margin-top: 37px;
  }

  .coolCase__rectangle_red {
    background: #FF4324;
    transform: rotate(-15deg);
    right: 139px;
  }

  .coolCase__rectangle_blue {
    background: #512CB7;
    transform: rotate(15deg);
    margin-top: 37px;
    right: 0;
  }
}