@media screen and (min-width: 1900px) {

.portfolio {
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.portfolio__header {
  padding: 96px 100px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.portfolio__rectangle {
  background-color: #ff4324;
  width: 245.09px;
  height: 200px;
  transform: rotate(-6.54deg);
  position: absolute;
  top: -20px;
  right: 100px;
}
.portfolio__container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
  /* width: 100vw; */
  padding-bottom: 57px;
}
.portfolio__emptyProject {
  width: calc(50% - 2px);
  height: calc((50vw - 2px) / 1.5);
  object-fit: cover;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 76px 76px 76px 95px;
  box-sizing: border-box;
  background-color: #512cb7;
  font-size: 77px;
  line-height: 100%;
  font-feature-settings: "salt" on, "liga" off;
  color: #ffffff;
  cursor: pointer;
  justify-content: space-between;
}
.portfolio__emptyProjectRectangle {
  transform: rotate(10.62deg) translateX(-70px);
  width: 194px;
  height: 94%;
  background-color: rgba(170, 188, 210, 0.29);
}
.portfolio__emptyProjectContent {
  width: 490px;
  background: url("../../../Media/ArrowRigthWhite.svg") left bottom no-repeat;
  padding-bottom: 115px;
  background-size: 89px !important;
  transition: background-position ease 0.3s;
  position: relative;
  z-index: 2;
}
.portfolio__emptyProject:hover > a > .portfolio__emptyProjectContent {
  background: url("../../../Media/ArrowRigthWhite.svg") 57px bottom no-repeat;
}
}