@media screen and (min-width: 320px) and (max-width: 949px) {
  .insights {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  .insights__topPart {
    background-color: #ff4324;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .insights__titles {
    display: flex;
    flex-direction: column;
    row-gap: 45px;
    width: 348px;
    padding: 57px 40px 52px;
    box-sizing: border-box;
  }
  .insights__titles > h3 {
    color: white;
  }
  .insights__mainInsight-container {
    position: relative;
    object-fit: cover;
    width: 100%;
    cursor: pointer;
    max-height: 563px;
    align-items: center;
    display: flex;
    overflow: hidden;
    margin: 57px 0 38px;
  }
  .insights__section-container {
    display: flex;
    column-gap: 22px;
    padding: 0 40px 60px;
    overflow: scroll;
  }
  .insights__section-container::-webkit-scrollbar {
    display: none;
  }
  .insights__moreBtn-container {
    padding: 46px 0 58px;
    align-self: center;
  }
}
