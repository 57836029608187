@import url("./PodcastInsightBlock_1920.css");
@import url("./PodcastInsightBlock_1000.css");
@import url("./PodcastInsightBlock_mobile.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .podcastInsightBlock__container {
    background-color: white;
    transform: translateY(-3px);
    box-sizing: border-box;
    padding: 35px 0;
  }
  .podcastInsightBlock {
    background-color: rgba(170, 188, 210, 0.27);
    padding: 62px 90px 69px;
    box-sizing: border-box;
    background: url('../../../Media/PodcastInsightCover.svg') no-repeat rgba(170, 188, 210, 0.27);
    background-size: 448px !important;
    background-position: calc(100% + 60px) bottom !important;
  }
  .podcastInsightBlock__text {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    height: 100%;
    width: 80%;
  }
  .podcastInsightBlock__section-container {
    width: fit-content;
    display: flex;
    column-gap: 23px;
  }
  .podcastInsightBlock__section {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
  }
  .podcastInsightBlock__description {
    font-size: 23px;
    padding-top: 7px;
  }
  .podcastInsightBlock__infoContainer {
    display: flex;
    column-gap: 43px;
    font-size: 20px;
    line-height: 120%;
    color: #949494;
    margin-top: 33px;
    /* position: relative; */
  }
  .podcastInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;

    color: rgba(69, 69, 69, 1);
    width: 245px;
    background-size: 41px;
    position: relative;
    cursor: pointer;
  }
}
