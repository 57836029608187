@import url("./OurInsights_1920.css");
@import url("./OurInsights_1000.css");
@import url("./OurInsights_700.css");
@import url("./OurInsights_428.css");
@import url("./OurInsights_375.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .ourInsights {
    padding: 78px 100px 118px 100px;
    box-sizing: border-box;
  }
  .ourInsights__container {
    margin-top: 45px;
    display: flex;
    column-gap: 71px;
  }
  .ourInsights__coverContainer {
    position: relative;
    object-fit: cover;
    width: 61%;
    max-width: 1024px;
    cursor: pointer;
  }
  
  .ourInsights__rightContainer {
    width: 29%;
    /* max-width: 330px; */
    display: flex;
    flex-direction: column;
  }
  .ourInsights__insightBlock {
    margin-bottom: 41px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
  }
  .ourInsights__titleContainer{
    width: 205px;
    justify-content: space-between;
    align-items: baseline;
  }
  .ourInsights__section {
    margin-bottom: 16px;
  }
  .ourInsights__moreBtn {
    width: fit-content;
    padding-right: 70px;
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    font-size: 20px;
    cursor: pointer;
  }

}
