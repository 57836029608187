@import url("./Insights_1920.css");
@import url("./Insights_1000.css");
@import url("./Insights_mobile.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .insights {
    padding: 69px 100px 77px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .insights__topPart {
    width: 100%;
  }
  .insights__titles {
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    max-width: 90%;
  }
  .insights__mainInsight-container {
    position: relative;
    object-fit: cover;
    width: 100%;
    cursor: pointer;
    max-height: 563px;
    align-items: center;
    display: flex;
    overflow: hidden;
    margin: 55px 0;
  }
  .insights__section-container {
    display: flex;
    column-gap: 22px;
    padding-bottom: 48px;
    border-bottom: rgba(232, 237, 243, 1) solid 2px;
    margin-bottom: 3px;
  }
  .insights__moreBtn-container {
    padding: 70px 0 0px;
  }

}
