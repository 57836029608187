@media screen and (min-width: 950px) and (max-width: 1479px) {
  .relatedProjects {
    padding: 42px 0 28px;
    box-sizing: border-box;
    display: flex;
    row-gap: 22px;
    flex-direction: column;
    overflow: hidden;
  }
  .relatedProjects > h2,
  .relatedProjects > h3 {
    padding-left: 50px;
  }
  .relatedProjects__projects {
    display: flex;
    overflow: scroll;
    column-gap: 4px;
    padding-top: 18px;
    padding: 18px 50px 0;
  }
  .relatedProjects__projects > .projectBlock {
    width: 53vw;
    flex-shrink: 0;
  }
  .relatedProjects__projects::-webkit-scrollbar {
    display: none;
  }
  .relatedProjects__project {
    width: calc(100vw / 1.9 - 6px);
    height: calc((100vw / 3) + 16px);
  }
}
