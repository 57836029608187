@media screen and (min-width: 1900px) {
    .specialistCard {
        display: flex;
        flex-direction: column;
        width: 26vw;
        flex-shrink: 0;
    }
    .specialistCard__inf {
        padding-top: 34px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;

      }
      .specialistCard__inf > div {
        font-size: 26px;
        width: 222px;
      }
    .specialistCard__imageContainer {
        height: 26vw;
        width: 100%;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        background-color: #E8EDF3;
      }
      .specialistCard__imageContainer img {
        object-fit: contain;
        object-position: bottom;
        height: 100%;
        width: 100%;
        transform: translateY(1px);
      }

    .specialistCard h4 {
        color: #18191F;
        font-weight: 700;
        font-size: 37px;
        line-height: 120%;
        margin-bottom: 11px;
    }

    .specialistCard p {
        font-size: 23px;
        line-height: 130%;
        width: 100%;
        padding-bottom: 32px;
    }

}