@media screen and (min-width: 600px) and (max-width: 950px) {
  .aboutUsTopBanner__section {
    width: 100%;
    background-color: white;
  }
  .aboutUsTopBanner {
    width: 100%;
    padding: 0 44px;
    box-sizing: border-box;
    height: calc(100vh - 79px);
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .aboutUsTopBanner__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .aboutUs__title {
    font-size: 28px;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    max-width: 500px;
  }

  .aboutUsTopBanner__pbn-bg {
    background: url("../../../Media/PBNBg.svg") center no-repeat;
    color: #fff;
    background-size: contain;
    padding: 10px;
  }
  .aboutUsTopBanner__arrow-down {
    background: url("../../../Media/AboutUsArrowDown.svg") center no-repeat;
    background-size: contain;
    width: 54px;
    height: 75px;
    margin-top: 72px;
    animation: pulsation 1s ease 1s infinite alternate both;
  }
}
@keyframes pulsation {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(63px);
  }
}
