@media screen and (min-width: 320px) and (max-width: 949px) {
  .project__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .project__titleContainer {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    width: 100%;
    padding: 40px 0px 46px 40px;
    box-sizing: border-box;
  }
  .project__section {
    font-size: 16px;
    padding-bottom: 7px;
  }
  .project__title{
    padding-right: 90px;
  }
  .project__tagArea {
    display: flex;
    column-gap: 12px;
    overflow: scroll;
  }
  .project__tagArea::-webkit-scrollbar {
    display: none;
  }
  .project__tag {
    color: #454545;
    font-size: 16px;
    line-height: 130%;
    padding: 9px 12px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1.5px solid #aabcd2;
    border-radius: 7px;
    flex-shrink: 0;
  }
  .project__tag:active {
    background: #ff4324;
    color: #fff;
    border-color: #ff4324;
    outline: none;
  }
  .project__tag:hover {
    background: #ff4324;
    color: #fff;
    border-color: #ff4324;
    outline: none;
  }
  .project__content {
    padding: 46px 40px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .project__texts {
    /* width: 50%; */
  }
  .project__mainText {
    margin-bottom: 46px;
    display: flex;
    flex-direction: column;
    row-gap: 19px;
  }
  .project__subtitle {
    box-sizing: border-box;
    /* padding-left: 47px; */
    font-size: 25px;
  }
  .project__subtitleIcon{
    width: 45px;
    height: 42px;
  }
  .project__text {
    font-size: 17px;
    line-height: 150%;
    color: #000000;
  }
  .projectInfo__container {
    width: 279px;
    /* min-width: 387px; */
    flex-shrink: 0;
  }
  .projectInfo__title {
    padding: 17px 0 38px;
    font-size: 25px;
    line-height: 120%;
    color: #323635;
  }
  .project__recommedations {
    display: flex;
    flex-direction: column;
    row-gap: 33px;
  }
  .project__getbackBtnArrow {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") left center /
      contain no-repeat;
    width: 41px;
    height: 19px;
    transform: rotate(180deg);
  }
  .project__getbackBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 13px 40px 46px;
    border: 2px solid #454545;
    border-radius: 6px;
    width: calc(100vw - 80px);
    height: 59px;
  }
  .project__getbackBtn {
    padding-left: 5%;
    font-size: 16px;
    color: #454545;
  }
  /* recommendations mobile only */

  .project__recommedations > h2 {
    padding: 0 40px;
  }
  .project__recommedationsCards {
    display: flex;
    overflow: scroll;
    column-gap: 20px;
    padding: 0 20px;
  }
  .project__recommedationsCards::-webkit-scrollbar {
    display: none;
  }
  .project__recommedationsCard {
    width: calc(100vw - 80px);
    flex-shrink: 0;
  }
  .project__recommedationsCard > a > .projectBlock {
    width: 100%;
    flex-shrink: 0;
  }
   /* recommendations mobile only end */

  .project__bigImage {
    object-fit: cover;
    height: calc(100vw / 1.69);
    padding: 0 20px 45px;
  }
  .project__smallImage {
    width: calc(100vw - 40px);
    object-fit: cover;
    height: calc(100vw / 1.69);
    transform: translateX(-20px);
  }
  .project__videoContainer {
    width: calc(100vw - 40px);
    height: calc(100vw / 1.69);
    position: relative;
    background-color: #949494;
    transition: all ease-in-out 0.4s 0s;
    margin-top: 27px;
  }

  .videoOpened {
    position: relative;
    left: 0px;
    transform: translateX(-20px);
    width: fit-content;
    transition: all ease-in 0.4s 0s;
  }
  .videoClosed {
    position: relative;
    left: 0px;
    transform: translateX(-20px);
    height: fit-content;
    transition: all ease-in 0.4s 0s;
  }
  .project__videoPlayBtn {
    background: url("../../../Media/PlayBtn.svg") center / contain no-repeat;
    width: 71px;
    height: 71px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 3;
  }
  .project__videoStopBtn {
    background: url("../../../Media/ArrowRigthWhite.svg") center / contain
      no-repeat;
    transform: rotate(-45deg);
    width: 75px;
    height: 55px;
    position: absolute;
    z-index: 3;
    bottom: 15px;
    left: 18px;
    cursor: pointer;
    transition: all ease-in-out 0.2s 0s;
    opacity: 0;
    z-index: 3;

  }
  .project__videoStopBtn:hover {
    opacity: 1;
  }
  .notVisible {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  .infographics__container {
    display: flex;
    flex-direction: column;
    row-gap: 46px;
    order: 2;
    margin-bottom: 46px;
  }
  .infographics__block {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }
  .infographics__number {
    font-size: 64px;
    line-height: 90%;
  }
  .infographics__text {
    font-size: 17px;
    width: 325px;
  }
  .coverSlider__container {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 473px;
    width: 100vw;
    overflow: hidden;
    height: 100%;
  }
  .slider__btn-prev,
  .slider__btn-next {
    position: absolute;
    /* background-color: brown; */
    width: 86px;
    height: 100px;
    z-index: 2;
   opacity: 0;
  }
  .slider__btn-prev {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: url("../../../Media/SliderArrowLeft.svg") center no-repeat;
    animation: leftBtn 1s ease 1.5s 1 forwards;
  }
  .slider__btn-next {
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(180deg);
    background: url("../../../Media/SliderArrowLeft.svg") center no-repeat;
    animation: rightBtn 1s ease 1.5s 1 forwards;
  }
  .slick-track {
    display: flex;
  }
  .slick-track > div > div {
    height: 100%;
    }
  .slick-slider {
    position: relative;
  }
  
@keyframes leftBtn {
  0% {
    opacity: 0;
    /* transform: scale(0.5) translateY(-50%) ; */
  }
  100% {
    opacity: 1;
    /* transform: scale(1) translateY(-50%) ; */
  }
}
@keyframes rightBtn {
  0% {
    opacity: 0;
    /* transform: scale(0.5) translateY(-50%) rotate(180deg); */
  }
  100% {
    opacity: 1;
    /* transform: scale(1) translateY(-50%) rotate(180deg); */
  }
}
}
