@import url("./ConnectToHead_1920.css");
@import url("./ConnectToHead_1000.css");
@import url("./ConnectToHead_mobile.css");



@media screen and (min-width: 1480px) and (max-width: 1899px) {
.connectToHead{
    padding: 62px 100px;
    box-sizing: border-box;
}
.connectToHead__container{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 36px 0 95px;
    background-color: rgba(170, 188, 210, 0.27);
    box-sizing: border-box;
    overflow: hidden;
    align-items: flex-end;
    position: relative;

}
.connectToHead__text{
    display: flex;
    flex-direction: column;
    row-gap: 61px;
    padding: 168px 0 143px;
    width: 63%;
    max-width: 689px;
}
.connectToHead__container > img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 429px;
  }
}