@media screen and (min-width: 320px) and (max-width: 949px) {

  .connectToHead {
    padding: 46px 40px;
    box-sizing: border-box;
  }
  .connectToHead__container {
    display: flex;
    height: calc(100vw * 2);
    max-height: 432px;
    width: 100%;
    padding: 0 0px 0 33px;
    background-color: rgba(170, 188, 210, 0.27);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  .connectToHead__text {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 33px 0 207px;
    width: 70%;
    /* min-width: 265px; */
    font-size: 25px;
  }
  .connectToHead__container > img {
    position: absolute;
    right: -20px;
    bottom: 0;
    /* height: 60%; */
    width: 45vw;
    /* min-width: 250px; */
    max-height: 100%;
  }
}
