@media screen and (min-width: 950px) and (max-width: 1479px) {
  .dignity {
    padding-top: 112px;
    padding-bottom: 50px;
}

.dignity__content {
    height: 776px;
    position: relative;
    display: flex;
    justify-content: center;
}

.dignity-bg {
    max-width: 100%;
    height: 537px;
    background: url("../../../Media/DignityBg.jpg") center / cover no-repeat;
    top: 0;
    z-index: 0;
    position: absolute;
}

.dignity__cards-block {
    display: flex;
    position: relative;
    top: 461px;
    height: fit-content;
    padding: 0 50px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-self: flex-start;
    column-gap: 37px;
}

.dignity__cards-block::-webkit-scrollbar {
    width: 0;
    height: 0;
}
}
