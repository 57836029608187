@media screen and (min-width: 600px) and (max-width: 950px) {
    .dignity {
        padding-top: 46px;
        padding-bottom: 39px;
    }

    .dignity__cards-block {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .dignity__content h2 {
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        padding-left: 37px;
        margin-bottom: 33px;
    }
}