@import url("./Button_1920.css");
@import url("./Button_1000.css");
@import url("./Button_700.css");



@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .button {
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-feature-settings: "salt" on, "liga" off;
    border: 2px solid #ff4324;
    border-radius: 6px;
    font-size: 20px;
    line-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all linear .3s;
  }
  .button:hover{
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
  }
  .header__button {
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    background-color: #ff4324;
    color: #fff;
    width: 182px;
    height: 43px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: "salt" on, "liga" off;
    justify-content: center;
    cursor: pointer;
  }
  .button.colored {
    background-color: #ff4324;
    color: #fff;
  }
}
