@media screen and (min-width: 600px) and (max-width: 949px) {
  .topBanner__section {
    position: relative;
    overflow: hidden;
  }
  .topBanner {
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
  }
  .topBanner__container {
    width: 70%;
    display: flex;
    margin: auto 0;
    flex-direction: column;
    padding-bottom: 70px;
  }
  .topBanner__text {
    z-index: 2;
    margin-bottom: 40px;
  }
  .topBanner__rectangles {
    position: absolute;
    right: 7.3%;
    top: calc((100% - 70px)/2);
    display: flex;
    transform: translateY(-50%);
    padding-bottom: 70px;
  }
  .topBanner__rectangle {
    width: 81px;
    height: 191px;
    background-color: #e8edf3;
    transition: background-color linear 0.6s;
  }
  .rightRectangle {
    transform: rotate(7deg) translate(1px, -5px);
  }
  .centerRectangle {
    transform: rotate(-9deg) translate(5px, -20px);
  }
  .leftRectangle {
    transform: rotate(7deg) translateY(-4px);
  }
  .violet {
    background-color: #512cb7;
  }
  .orange {
    background-color: #ff4324;
  }
  .pink {
    background-color: #ffc7cb;
  }
  .topBanner__btn-block {
    background: url("../../../Media/ArrowRight.svg") center left no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 268px;
    height: 49px;
    background-size: contain;
    z-index: 2;
  }
  .topBanner__clients {
    width: 100%;
    height: 70px;
    background-color: #454545;
    display: flex;
    align-items: center;
    column-gap: 45px;
    overflow: scroll;
    padding: 0 5%;
    box-sizing: border-box;
    pointer-events: none;
    bottom: 0;
    z-index: 3;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .topBanner__clients::-webkit-scrollbar {
    display: none;
  }
  .topBanner__clients.hidden {
    opacity: 0;
  }

  .topBanner__clients-items {
    flex-shrink: 0;
    display: flex;
    gap: 45px;
    justify-content: space-around;
  }

  .marquee {
    animation: scrollClients 30s linear infinite;
  }

  .topBanner__clientLogo {
    display: block;
    height: 70px;
    transition: all 0.1s ease-in-out;
  }
  
  @keyframes scrollClients {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 45px));
    }
  }

}
