@import url("./OurServices_1920.css");
@import url("./OurServices_1000.css");
@import url("./OurServices_mobile.css");


@media screen and (min-width: 1480px) and (max-width: 1899px) {
.ourServices{
    padding: 121px 100px;
    box-sizing: border-box;
    display: flex;
    row-gap: 90px;
    flex-direction: column;
}
.ourServices__titles{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
}
.ourServices__titles > h2{
    max-width: 1050px;
    width: 90%;
}
.ourServices__services-container{
    padding-top: 33px;
    width: 90%;
}
}