@media screen and (min-width: 950px) and (max-width: 1479px) {
  .serviceDescription {
    padding: 0px 0px 12px;
    box-sizing: border-box;
    display: flex;
    column-gap: 37px;
    overflow-x: scroll;
  }
  .serviceDescription__description {
    background: #e8edf3;
    border-radius: 20px;
    padding: 95px 83px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    box-sizing: border-box;
    width: calc(100vw - 148px);
    margin-left: 50px;
    flex-shrink: 0;
  }
  .serviceDescription::-webkit-scrollbar {
    display: none;
  }
  .serviceDescription__description > h3 {
    color: #a1a1a1;
  }
  .serviceDescription__description > p {
    font-size: 23px;
    line-height: 140%;
  }
  .serviceDescription__decoration {
    width: 351px;
    height: auto;
    border-radius: 20px;
    flex-shrink: 0;
    min-height: 386px;
    background-size: 601px;
    margin-right: 50px;
    object-fit: cover;
  }
}
