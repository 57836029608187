@media screen and (min-width: 950px) and (max-width: 1479px) {
  .coolCase {
    padding: 63px 20px 71px 90px;
    overflow: hidden;
  }

  .coolCase__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .coolCase__text {
    font-weight: 400;
    font-size: 38px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .coolCase__arrow {
    background: url("../../../Media/ArrowRight.svg") center no-repeat;
    background-size: contain;
    width: 74px;
    height: 54px;
    margin-top: 40px;
  }

  .coolCase__rectangles {
    width: 541px;
    height: 423px;
    display: flex;
    position: relative;
  }

  .coolCase__rectangle {
    width: 152px;
    height: 363px;
    position: absolute;
  }

  .coolCase__rectangle_pink {
    background: #ffc7cb;
    transform: rotate(15deg);
    right: 331px;
    margin-top: 37px;
  }

  .coolCase__rectangle_red {
    background: #ff4324;
    transform: rotate(-15deg);
    right: 139px;
  }

  .coolCase__rectangle_blue {
    background: #512cb7;
    transform: rotate(15deg);
    margin-top: 37px;
    right: 0;
  }
}
