@media screen and (min-width: 320px) and (max-width: 949px) {
  .podcastPopup__bubble {
    position: absolute;
    max-width: 344px;
    padding: 20px 10% 50px;
    box-sizing: border-box;
    z-index: 4;
    animation: opacity 3s ease 0s 1 forwards;
  }
  .podcastPopup__bubble_closing {
    position: absolute;
    z-index: 4;
    width: 30px;
    height: 30px;
    overflow: hidden;
    animation: closing 3s ease 0s 1 forwards;
  }
  .podcastPopup__links {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 10px;
  }
  .podcastPopup__link {
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #454545;
    border: 1.5px solid #aabcd2;
    border-radius: 7px;
    padding: 7px 10px;
    box-sizing: border-box;
  }
  .podcastPopup__link:hover {
    color: #ff4324;
  }
  .podcastPopup__mobileTitle {
    font-weight: 700;
    font-size: 23px;
    line-height: 110%;
    color: #18191f;
    padding-bottom: 22px;
  }
  .podcastPopup__mobileCloseBtn {
    background: url("../../../Media/PodcastPopupCloseBtn.svg") center no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 28px;
    right: 23px;
    animation: rotation 2s ease 2s 1 forwards;
    opacity: 0;
  }
  .podcastPopup__mobileCloseBtn_closing {
    background: url("../../../Media/PodcastPopupCloseBtn.svg") center no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 28px;
    right: 23px;
    animation: rotationClose 2s ease 0s 1 forwards;
  }
}
@keyframes opacity {
  0% {
    width: 30px;
    height: 30px;
    border-radius: 7px;

    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center top /
      cover no-repeat;
    top: -30px;
    right: 0;
  }
  25% {
    width: calc(100vw - 5%);
    height: 270px;
    border-radius: 7px;
    top: -300px;
    right: 0;
    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center top /
      cover no-repeat;
  }
  50% {
    width: calc(100vw - 5%);
    height: 280px;
    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center top /
      cover no-repeat;
    top: -300px;
    right: 0;
  }

  100% {
    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center /
      contain no-repeat;
    width: calc(100vw - 5%);

    height: 280px;
    top: -300px;
    right: 0;
  }
}
@keyframes closing {
  0% {
    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center /
      contain no-repeat;
    width: calc(100vw - 5%);
    height: 280px;
    top: -300px;
    right: 0;
    transform: scaleY(1);
  }
  25% {
    width: calc(100vw - 5%);
    height: 280px;
    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center top /
      cover no-repeat;
    top: -300px;
    right: 0;
    transform: scaleY(1);
  }
  50% {
    width: calc(100vw - 5%);
    height: 260px;
    border-radius: 7px;
    top: -300px;
    right: 0;
    background: url("../../../Media/PodcastPopupBubbleMobile.svg") center top /
      cover no-repeat;
    transform: scaleY(1);
  }
  100% {
    width: 30px;
    height: 30px;
    transform: scaleY(0.5);
    border-radius: 7px;
    background: white;
    top: -50px;
    right: 0;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(45deg);
    opacity: 0;
  }
  50% {
    transform: rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes rotationClose {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: rotate(45deg);
    opacity: 0;
  }
}
