@media screen and (min-width: 1900px) {
  .dignity {
    padding-top: 112px;
    padding-bottom: 50px;
  }

  .dignity__content {
    height: 1142px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .dignity-bg {
    max-width: 100%;
    height: 935px;
    background: url("../../../Media/DignityBg.jpg") center / cover no-repeat;
    top: 0;
    z-index: 0;
    position: absolute;
  }

  .dignity__cards-block {
    display: flex;
    padding: 0 100px;
    z-index: 1;
    overflow: auto;
    top: 722px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    column-gap: 51px;
    align-self: flex-start;
    height: fit-content;
  }

  .dignity__cards-block::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
