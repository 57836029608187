@media screen and (min-width: 950px) and (max-width: 1479px) {

  .article__container {
    padding: 76px 50px 81px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 72px;
  }
  .article__titleContainer {
    display: flex;
    flex-direction: column;
    row-gap: 27px;
  }
  .article__section {
    font-size: 20px;
  }
  .article__content{
    display: flex;
    column-gap: 61px;
  }
  .article__mainText {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    /* width: calc(100vw - 445px); */
  }
  .article__text {
    font-weight: 400;
    font-size: 25px;
    line-height: 150%;
  }
  .article__subtitle {
    padding-bottom: 40px;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
  }
  .article__cover {
    width: 282px;
    height: 261px;
  }
  .article__cover > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .article__recommendation {
    /* opacity: 0.6; */
    margin: 40px 0 62px;
  }
  .article__recommendation > span {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
  }
  .article__recommendation > p {
    font-size: 25px;
    line-height: 120%;
    margin: 20px 0 41px;
    color: rgba(148, 148, 148, 1);
  }
  .article__recomendations {
    display: flex;
    column-gap: 20px;
    align-items: stretch;
  }
  .recommendationColored {
    background-color: rgba(232, 237, 243, 1);
    padding: 60px;
    box-sizing: border-box;
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 285px;
    margin-top: 0;
  }
  .recommendationColored > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
  }
  .recommendationColored > p {
    font-size: 25px;
    line-height: 120%;
    margin: 20px 0 41px;
    color: rgba(69, 69, 69, 1);
  }
  .article__mainText > h4,
  .article__mainText > h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
    margin-top: 27px;
    color: #454545;
  }
  .article__mainText > h2 {
    font-weight: 700;
    margin-bottom: 19px;
    font-size: 33px;
  }
  .article__mainText > img,
  .article__mainText > p > img {
    width: 100%;
    object-fit: contain;
  }
  .article__mainText > p {
    font-size: 25px;
    line-height: 150%;
  }
  .article__author {
    font-size: 20px;
    line-height: 130%;
    color: #949494;
    margin-top: 40px;
    width: 459px;
  }
  .article__pdfCover {
    width: 261px;
    padding-bottom: 40px;
  }
}
