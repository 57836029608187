@import url("./FacilitiesBlock_1920.css");
@import url("./FacilitiesBlock_1000.css");
@import url("./FacilitiesBlock_700.css");
@import url("./FacilitiesBlock_428.css");
@import url("./FacilitiesBlock_375.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .facilitiesBlock__arrow {
    position: absolute;
    bottom: 4px;
    right: -127px;
    background: url("../../../Media/AboutUsArrowDown.svg") center no-repeat;
    width: 32px;
    height: 44px;
    background-size: contain;
    cursor: pointer;
    transition: transform 0.5s ease-in;
  }
  .facilitiesBlock__arrow.rotated {
    transform: rotate(180deg);
  }
  .facilitiesBlock {
    display: flex;
    row-gap: 22px;
    column-gap: 24px;
    flex-wrap: wrap;
    height: fit-content;
    transition: all 1s ease;
    position: relative;
  }
  .facility {
    font-size: 20px;
    line-height: 34px;
    display: flex;
    align-items: center;
    /* text-align: center; */
    font-feature-settings: "salt" on, "liga" off;
    color: #454545;
    border: 2px solid #454545;
    border-radius: 6px;
    padding: 8px 24px;
    box-sizing: border-box;
    transition: all ease 0.3s;
  }
  .facility:hover {
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
  }
  .facility_focused{
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
  }
}
