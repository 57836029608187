@media screen and (min-width: 320px) and (max-width: 949px) {
  .projects__top {
    display: flex;
    flex-direction: column;
    padding: 57px 0px 52px 0px;
    box-sizing: border-box;
    row-gap: 53px;
    background-color: #ff4324;
    overflow: hidden;
  }
  .projects__title {
    display: flex;
    flex-direction: column;
    row-gap: 45px;
    width: 362px;
    color: #fff;
    padding-left: 39px;
  }
  .projects__title > h3 {
    color: #fff;
  }
  .projects__tags {
    display: flex;
    column-gap: 12px;
    overflow: scroll;
    padding: 0 39px;
  }
  .projects__tags::-webkit-scrollbar {
    display: none;
  }
  .projects__mainProject {
    width: 100%;
    height: 480px;
    background-color: rgba(72, 0, 188, 0.5);
  }
  .projects__projects {
    display: flex;
    flex-direction: column;
    row-gap: 46px;
    padding: 40px;
  }
  .projects__projects > .projectBlock {
    width: 100%;
  }
  .projects__btnContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
  }
  .projects__tag {
    font-size: 20px;
    display: flex;
    background-color: transparent;
    height: 49px;
    align-items: center;
    text-align: center;
    color: #e8edf3;
    border: 2px solid #e8edf3;
    border-radius: 7px;
    padding: 0px 20px;
    transition: all ease 0.3s;
    box-sizing: border-box;
    flex-shrink: 0;
    cursor: pointer;
  }
  .projects__tag_focused {
    color: #454545;
    border-color: #e8edf3;
    background-color: #e8edf3;
    
  }
}
