@media screen and (min-width: 1900px) {
  .workWithUs {
    display: flex;
  }
  .workWithUs__content {
    padding: 133px 100px 135px;
  }
  .workWithUs__title {
    padding: 55px 0 85px;
    font-size: 77px;
  }
  .workWithUs__btnsContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .workWithUs__arrow {
    background: url("../../../Media/ArrowRight.svg") center no-repeat;
    width: 74px;
    height: 54px;
    margin-right: 23px;
  }
  .workWithUs__image {
    background: url("../../../Media/WorkWithUsPhoto.png") right no-repeat;
    width: 66%;
    background-size: contain;
  }
}
