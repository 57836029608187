@media screen and (min-width: 400px) and (max-width: 599px) {
  .workWithUs {
    display: flex;
    padding: 48px 40px 26px;
  }
  .workWithUs__content {
    width: 100%;
  }
  .workWithUs__title {
    padding: 29px 21% calc(100vw * 1.05) 0;
    font-size: 32px;
    line-height: 110%;
    min-width: 282px;
    background: url("../../../Media/WorkWithUsPhotoMobile.png") center 120px/100%
      no-repeat;
  }
  .workWithUs__btnsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }
}
