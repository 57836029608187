@media screen and (min-width: 320px) and (max-width: 399px) {
  .time__toListen {
    background: url("../../../Media/TimeToListenDarkIcon.svg") left 3px
      no-repeat;
    padding-left: 18px;
    margin: 2px 0 0;
    font-size: 15px;
    line-height: 120%;
    color: rgba(148, 148, 148, 1);
  }
  .time__toRead {
    background: url("../../../Media/TimeToReadDarkIcon.svg") left top no-repeat;
    padding-left: 18px;
    margin: 2px 0 0;
    font-size: 15px;
    line-height: 120%;
    color: rgba(148, 148, 148, 1);
  }
  .time__toListen.timeMobile {
    margin: 51px 0 0;
  }
  .time__toRead.timeMobile {
    margin: 51px 0 0;
  }
}
