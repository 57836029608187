@media screen and (min-width: 320px) and (max-width: 800px) {
  .specialistCard {
    display: flex;
    flex-direction: column;
    width: 80vw;
    flex-shrink: 0;
    background-color: #e8edf3;
  }

  .specialistCard__inf {
    background-color: #18191f;
    padding: 33px 26px 30px 30px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .specialistCard__inf > div {
    font-size: 16px;
    width: 143px;
  }
  .specialistCard__inf > div:hover {
    color: #ffffff;
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(58deg)
      brightness(105%) contrast(101%);
  }
  .specialistCard__imageContainer {
    height: 316px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .specialistCard__imageContainer img {
    object-fit: contain;
    object-position: bottom;
    height: 100%;
    width: 100%;
    transform: translateY(1px);
  }

  .specialistCard h4 {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 11px;
  }

  .specialistCard p {
    font-size: 16px;
    line-height: 130%;
    width: 100%;
    margin-bottom: 30px;
  }

}
