@media screen and (min-width: 400px) and (max-width: 679px) {
  .facilitiesBlock {
    display: flex;
    row-gap: 19px;
    column-gap: 19px;
    flex-wrap: wrap;
    position: relative;
    width: 343px;
  }
  .facilitiesBlock_services_mobile {
    display: flex;
    column-gap: 19px;
    overflow: scroll;
    padding: 0 40px;
  }
  .facilitiesBlock_services_mobile::-webkit-scrollbar {
    display: none;
  }
  .facility {
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    font-feature-settings: "salt" on, "liga" off;
    color: #454545;
    border: 2px solid #454545;
    border-radius: 6px;
    padding: 14px 22px;
    box-sizing: border-box;
  }
  .facility_focused {
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
  }
  .facility_mobile {
    font-size: 20px;
    display: flex;
    background-color: transparent;
    height: 49px;
    align-items: center;
    text-align: center;
    color: #e8edf3;
    border: 2px solid #e8edf3;
    border-radius: 7px;
    padding: 0px 20px;
    transition: all ease 0.3s;
    box-sizing: border-box;
    flex-shrink: 0;
    cursor: pointer;
  }
  .facility_mobile_focused {
    color: #454545;
    border-color: #e8edf3;
    background-color: #e8edf3;
  }
}
