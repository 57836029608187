@media screen and (min-width: 1900px) {

.ourServices{
    padding: 137px 133px 105px;
    box-sizing: border-box;
    display: flex;
    row-gap: 120px;
    flex-direction: column;
}
.ourServices__titles{
    display: flex;
    flex-direction: column;
    row-gap: 31px;
}
.ourServices__titles > h2{
    max-width: 1594px;
}
.ourServices__services-container{
    padding-top: 42px;
    width: 92%;
}
}