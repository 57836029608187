@media screen and (min-width: 320px) and (max-width: 949px) {
  .podcastInfo {
    padding: 46px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .podcastInfo__textContainer {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 0 30px 0 40px;
    align-items: center;
  }
  .podcastInfo__text {
    line-height: 150%;
    font-size: 17px !important;
  }
  .podcastInfo__textContainer > h2 {
    font-size: 23px;
  }
  .podcastInfo__mediaBlock {
    padding: 30px 30px 34px;
    box-sizing: border-box;
    background: #e8edf3;
    border-radius: 6px;
    margin: 46px 40px;
    /* min-width: 334px; */
  }
  .podcastInfo__coverMobile {
    background: url("../../../Media/PodcastGeneralCover.png") center / contain
      no-repeat;
    width: calc(100vw - 70px);
    height: calc(100vw - 70px);
  }
  .podcastInfo__mediaTitle {
    font-weight: 700;
    font-size: 23px;
    line-height: 110%;
    padding-bottom: 22px;
  }
  .podcastInfo__mediaLinks {
    display: flex;
    flex-wrap: wrap;
    color: #454545;
    column-gap: 13px;
    row-gap: 11px;
    font-size: 16px;
    line-height: 130%;
  }
  .podcastInfo__mediaLinks > a {
    border: 1.5px solid #aabcd2;
    border-radius: 7px;
    padding: 9px 12px;
  }
  .podcastInfo__mobileTitle{
    font-size: 25px;
    font-weight: 700;
    margin: 0 40px 0px 40px;
  }
}
