@import url("./NotFound_mobile.css");

@media screen and (min-width: 950px){

.notFound {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  height: calc(100vh - 80px);
}
.notFound__rectangles {
  background: url("../../Media/Error404.svg") center / contain no-repeat;
  width: 34%;
  height: 46%;
  margin-bottom: 3%;
  max-width: 800px;
}
.notFound__text {
  font-size: 33px;
  line-height: 120%;
  max-width: 720px;
  text-align: center;
}
}