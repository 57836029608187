@import url("./OurSpecialists_1920.css");
@import url("./OurSpecialists_1000.css");
@import url("./OurSpecialists_mobile.css");

@media screen and (min-width: 1450px) and (max-width: 1899px) {
  .ourSpecialists {
    padding: 45px 0px 45px;
  }

  .ourSpecialists__content h3 {
    margin: 0 0 23px 90px;
    font-size: 20px;
  }

  .ourSpecialists__content h2 {
    margin: 0 0 62px 90px;
  }

  .ourSpecialists__cards-block {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    column-gap: 58px;
    align-items: stretch;
    padding: 0 90px;
    scroll-behavior: smooth;
  }

  .ourSpecialists__cards-block::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .ourSpecialists__buttonContainer {
    display: flex;
    justify-content: space-between;
    padding: 0px 90px 20px;
  }

  .ourSpecialists__btn {
    width: 130px;
    height: 70px;
    background: url("../../../Media/ArrowRigthWhite.svg") center / 55% no-repeat;
    flex-shrink: 0;
    opacity: 0;
    transition: all ease 0.4s;
    padding: 20px;
  }
  ._visible {
    opacity: 1;
    transition: all ease 0.4s;
    cursor: pointer;
  }
  .ourSpecialists__btn_rotated {
    transform: rotate(180deg);
  }
}
