@media screen and (min-width: 320px) and (max-width: 800px) {
    .ourSpecialists {
        padding: 40px 0px 40px;
    }

    .ourSpecialists__content h3 {
        color: #18191F;
        margin-bottom: 23px;
        font-weight: 700;
        font-size: 28px;
        line-height: 110%;
        padding-left: 37px;
    }

    .ourSpecialists__cards-block {
        display: flex;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        column-gap: 20px;
        align-items: stretch;
        padding: 0 20px;
    }

    .ourSpecialists__cards-block::-webkit-scrollbar {
       display: none;
    }
}