@import url("./AboutUsSlide_1920.css");
@import url("./AboutUsSlide_1000.css");
@import url("./AboutUsSlide_700.css");
@import url("./AboutUsSlide_428.css");
@import url("./AboutUsSlide_375.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .aboutUsSlide__section {
    width: 100%;
    background-color: #fff;
    height: calc(100vh + 1px);
    position: absolute;
  }

  .aboutUsSlide {
    width: 100%;
    height: 100%;
    padding: 82px 69px 82px 112px;
  }

  .aboutUsSlide-bgBlue {
    background: url("../../../Media/ExpertiseBg.png") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
    z-index: 3;
  }

  .aboutUsSlide-bgWhite {
    background: url("../../../Media/ExperienceBg.jpg") center no-repeat, white;
    background-size: cover;
    box-sizing: border-box;
    z-index: 2;
  }

  .aboutUsSlide-bgRed {
    background: url("../../../Media/AdvantageBg.png") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
    z-index: 1;
  }

  .aboutUsSlide__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .aboutUsSlide__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .aboutUsSlide__text h2 {
    font-size: 58px;
    font-weight: 400;
    max-width: 815px;
    line-height: 110%;
    margin-bottom: 40px;
  }

  .aboutUsSlide__text p {
    font-size: 23px;
    font-weight: 400;
    max-width: 546px;
    line-height: 150%;
  }

  .aboutUsSlide__paragraph {
    display: flex;
    flex-direction: column;
    row-gap: 39px;
  }

  .aboutUsSlide__paragraph-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  .aboutUsSlide__paragraph-item p {
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    color: rgba(232, 237, 243, 0.4);
  }

  .aboutUsSlide__paragraph-item__line {
    height: 100%;
    width: 3px;
    border-radius: 8px;
    background: rgba(232, 237, 243, 0.4);
    margin-left: 67px;
  }

}
