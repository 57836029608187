@media screen and (min-width: 320px) and (max-width: 399px) {
  .insightWithCover__textContainer {
    width: 100%;
    padding: 21px 20px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .insightWithCover__textContainer.mainpage {
    background-color: rgba(24, 25, 31, 1);
  }
  .insightWithCover__textContainer > p {
    color: #fff;
  }
  .insightWithCover__textContainer.insightsPage > p {
    font-weight: 700;
    font-size: 33px;
  }
  .insights__mainInsight-container_hovered {
    backdrop-filter: blur(5px);
    position: absolute;
    inset: 0;
    background: rgba(0, 10, 6, 0.7);
    color: white;
    z-index: 2;
    padding: 75px 172px 75px 70px;
    display: flex;
    flex-direction: column;
  }

  .insights__mainInsight-section_hovered {
    color: rgba(232, 237, 243, 1);
    font-size: 22px;
  }
  .insights__mainInsight-title_hovered {
    font-size: 33px;
    padding: 15px 0 43px;
    font-weight: 700;
  }
  .insights__mainInsight-description_hovered {
    font-size: 23px;
    line-height: 150%;
  }
  .insights__mainInsight-btn_hovered {
    background: url("../../../Media/ArrowRigthWhiteRoundCorners.svg") right center
      no-repeat;
    /* padding-right: 62px; */
    margin-top: auto;
    color: white;
    width: 170px;
    background-size: 41px;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: "salt" on, "liga" off;
    box-sizing: border-box;
  }
}
