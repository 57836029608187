@media screen and (min-width: 320px) and (max-width: 949px) {
  .article__container {
    padding: 40px 20px 58px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 37px;
  }
  .article__titleContainer {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    padding: 0 20px;
  }
  .article__title {
    font-size: 28px;
  }
  .article__section {
    font-size: 16px;
  }
  .article__content {
    display: flex;
    flex-direction: column;
  }
  .article__mainText {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    /* width: calc(100vw - 445px); */
  }
  .article__mainText > h4,
  .article__mainText > h3 {
    font-weight: 400;
    font-size: 23px;
    line-height: 150%;
    margin-top: 17px;
    color: #454545;
    padding: 0 20px;
  }
  .article__mainText > h2 {
    font-weight: 400;
    font-size: 23px;
    padding: 0 20px;
  }
  .article__mainText > img,
  .article__mainText > p > img {
    width: 100%;
    object-fit: contain;
    margin-top: 17px;
  }
  .article__mainText > p {
    font-size: 17px;
    line-height: 150%;
    padding: 0 20px;
  }
  .article__getBackBtn {
    width: 346px;
    height: 59px;
    border: 2px solid #454545;
    border-radius: 6px;
    display: flex;
    padding: 0 24px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    margin: 48px auto 0;
  }
  .article__getBackBtnArrow {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") left center
      no-repeat;
    width: 42px;
    height: 15px;
    transform: rotate(180deg);
  }
  .article__saveBtnMobile {
    width: 346px;
    height: 59px;
    border: 2px solid #454545;
    border-radius: 6px;
    display: flex;
    padding: 0 24px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    margin: 11px auto 0;
  }
  .article__saveBtnArrow {
    background: url("../../../Media/GoUpArrow.svg") left center no-repeat;
    width: 42px;
    height: 22px;
    transform: rotateX(180deg);
  }
  .article__text {
    font-weight: 400;
    font-size: 25px;
    line-height: 150%;
  }
  .article__subtitle {
    padding-bottom: 40px;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
  }
  .article__cover {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    margin-bottom: 37px;
  }
  .article__cover > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .article__coverMobile {
    object-fit: cover;
    width: 100%;
  }
  .article__recommendation {
    /* opacity: 0.6; */
    margin: 37px 0px 0;
    background-color: #e8edf3;
    width: 100%;
    padding: 37px 32px;
  }
  .article__recommendation > div > span {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    margin-right: 20px;
  }
  .article__recommendation > p {
    font-size: 23px;
    line-height: 120%;
    margin: 19px 0 34px;
    color: rgba(24, 25, 31, 1);
  }
  .article__recomendations {
    display: flex;
    column-gap: 20px;
    align-items: stretch;
    flex-wrap: wrap;
    background-color: rgba(232, 237, 243, 1);
    margin-top: 8px;
  }
  .recommendationColored {
    background-color: rgba(232, 237, 243, 1);
    margin: 37px 32px;

    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 227px;
  }
  .recommendationColored:first-of-type {
    border-bottom: 2px solid #aabcd2;
    margin: 37px 32px 0;
    padding-bottom: 37px;
  }
  .recommendationColored > span {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    margin-right: 20px;
  }
  .recommendationColored > p {
    font-size: 23px;
    line-height: 120%;
    margin: 19px 0 34px;
    color: rgba(24, 25, 31, 1);
  }

  .article__author {
    font-size: 20px;
    line-height: 130%;
    color: #949494;
    margin: 0px 20px 0px;
  }
  .article__pdfCover {
    width: 261px;
    padding-bottom: 40px;
  }
}
