@media screen and (min-width: 950px) and (max-width: 1479px) {
  .portfolio {
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .portfolio__header {
    padding: 99px 50px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .portfolio__rectangle {
    background-color: #ff4324;
    width: 245.09px;
    height: 200px;
    transform: rotate(-6.54deg);
    position: absolute;
    top: -20px;
    right: 107px;
  }
  .portfolio__container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
    padding-bottom: 57px;
  }
  .portfolio__emptyProject {
    width: calc(50% - 2px);
    height: calc((50vw - 2px) / 1.5);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 71px 65px 72px 47px;
    box-sizing: border-box;
    background-color: #512cb7;
    font-size: 38px;
    line-height: 100%;
    font-feature-settings: "salt" on, "liga" off;
    color: #ffffff;
    cursor: pointer;
  }
  .portfolio__emptyProjectRectangle {
    transform: rotate(10.62deg);
    width: 78.92px;
    height: 100%;
    background-color: rgba(170, 188, 210, 0.29);
  }
  .portfolio__emptyProjectContent {
    width: 240px;
    background: url("../../../Media/ArrowRigthWhite.svg") left bottom no-repeat;
    padding-bottom: 95px;
    /* background-size: 89px 65px; */
    transition: background-position ease 0.3s;
    position: relative;
    z-index: 2;
    background-size: 63px !important;
  }
  .portfolio__emptyProject:hover > a > .portfolio__emptyProjectContent {
    background: url("../../../Media/ArrowRigthWhite.svg") 57px bottom no-repeat;
  }
}
