@media screen and (min-width: 600px) and (max-width: 949px) {
  .contactUs {
    display: flex;
    padding: 64px 50px 57px;
    column-gap: 60px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-wrap: wrap;
align-items: flex-end;
  }
  .contactUs__contactZone {
    width: 50%;
  }
  .contactUs__formContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 17px;
    padding-top: 40px;
  }
  .contactUs__input {
    width: 100%;
  }
  .contactUs__input:last-of-type {
    margin-bottom: 4px;
  }
  .contactUs__contacts {
    background: url('../../../Media/ArrowRight.svg') top left no-repeat;
   background-size: 74px;
    width: calc(50% - 60px);
    display: flex;
    flex-direction: column;
    row-gap: 21px;
    padding-top: 85px;
  }
}
