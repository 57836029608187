@media screen and (min-width: 1900px) {
    .aboutUsSlide__section {
        width: 100%;
        background-color: #fff;
        height: calc(100vh + 1px);
      }
    .aboutUsSlide {
        width: 100%;
        height: 100%;
        padding: 109px 92px 109px 141px;
    }

    .aboutUsSlide-bgBlue {
        background: url("../../../Media/ExpertiseBg.png") center no-repeat;
        background-size: cover;
        box-sizing: border-box;
        color: #fff;
    }

    .aboutUsSlide-bgWhite {
        background: url("../../../Media/ExperienceBg.jpg") center no-repeat;
        background-size: cover;
        box-sizing: border-box;
    }

    .aboutUsSlide-bgRed {
        background: url("../../../Media/AdvantageBg.png") center no-repeat;
        background-size: cover;
        box-sizing: border-box;
        color: #fff;
    }

    .aboutUsSlide__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .aboutUsSlide__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .aboutUsSlide__text h2 {
        font-size: 77px;
        font-weight: 400;
        max-width: 1087px;
        line-height: 110%;
        margin-bottom: 53px;
    }

    .aboutUsSlide__text p {
        font-size: 30px;
        font-weight: 400;
        max-width: 728px;
        line-height: 150%;
    }

    .aboutUsSlide__paragraph {
        display: flex;
        flex-direction: column;
        row-gap: 51px;
    }

    .aboutUsSlide__paragraph-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }

    .aboutUsSlide__paragraph-item p {
        font-size: 26px;
        font-weight: 400;
        line-height: 130%;
        color: rgba(232, 237, 243, 0.3);
    }

    .aboutUsSlide__paragraph-item__line {
        height: 100%;
        width: 4px;
        border-radius: 8px;
        background: rgba(232, 237, 243, 0.3);
        margin-left: 75px;
    }
}