@media screen and (min-width: 950px) and (max-width: 1479px) {
  .ourInsights {
    padding: 77px 50px 118px 50px;
    box-sizing: border-box;
  }
  .ourInsights__container {
    margin-top: 45px;
    display: flex;
    column-gap: 69px;
  }
  .ourInsights__coverContainer {
    object-fit: cover;
    width: 61%;
    max-width: 1024px;
    cursor: pointer;
  }
  .ourInsights__coverContainer > a {
    position: relative;
    height: 100%;
  }
  .ourInsights__coverShadow {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      179.9deg,
      rgba(24, 25, 31, 0) 55.77%,
      rgba(24, 25, 31, 0.410548) 72.46%,
      #18191f 105.83%
    );
    position: absolute;
    inset: 0;
  }
  .ourInsights__textContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 58%;
    max-width: 310px;
    margin: 59px 52px;
    display: flex;
    flex-direction: column;
  }
  .ourInsights__textContainer > p {
    font-weight: 700;
    color: #fff;
  }
  .ourInsights__rightContainer {
    width: 41%;
    max-width: 334px;
    display: flex;
    flex-direction: column;
  }
  .ourInsights__titleContainer {
    width: 227px;
    justify-content: space-between;

  }
  .ourInsights__insightBlock {
    margin-bottom: 41px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;

  }
  .ourInsights__insightBlock > a {
    flex-direction: column;
  }
  .ourInsights__section {
    margin-bottom: 16px;
  }
  .ourInsights__moreBtn {
    width: 230px;
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    font-size: 20px;
    cursor: pointer;
  }
}
