@import url("./DignityCard_1920.css");
@import url("./DignityCard_1000.css");
@import url("./DignityCard_428-700.css");
@import url("./DignityCard_375.css");


@media screen and (min-width: 1480px) and (max-width: 1899px) {

    .dignityCard {
        height: 315px;
        width: 597px;
        box-sizing: border-box;
        background-color: #E8EDF3;
        border-radius: 6px;
        display: flex;
        padding: 0 70px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .dignityCard__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 23px;
    }

    .dignityCard h4 {
        font-weight: 700;
        font-size: 33px;
        line-height: 130%;
    }

    .dignityCard p {
        font-size: 25px;
        line-height: 130%;
        font-weight: 400;
    }
}