@media screen and (min-width: 600px) and (max-width: 950px) {
  .aboutUsSlide__section {
    width: 100%;
    background-color: #fff;
    height: 100vh;
    position: absolute;
  }
  .aboutUsSlide {
    height: 100vh;
    width: 100%;
    padding: 0px 48px 0px 97px;
  }

  .aboutUsSlide-bgBlue {
    background: url("../../../Media/ExpertiseBgMobile.jpg") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
  }

  .aboutUsSlide-bgWhite {
    background: url("../../../Media/ExperienceBgMobile.jpg") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
  }

  .aboutUsSlide-bgRed {
    background: url("../../../Media/AdvantageBgMobile.jpg") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
  }

  .aboutUsSlide__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .aboutUsSlide__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .aboutUsSlide__text h2 {
    font-size: 38px;
    font-weight: 400;
    max-width: 533px;
    line-height: 110%;
    margin-bottom: 63px;
  }

  .aboutUsSlide__text p {
    font-size: 23px;
    font-weight: 400;
    max-width: 546px;
    line-height: 150%;
  }

  .aboutUsSlide__paragraph {
    display: none;
    flex-direction: column;
    row-gap: 39px;
  }

  .aboutUsSlide__paragraph-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  .aboutUsSlide__paragraph-item p {
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    color: rgba(232, 237, 243, 0.4);
  }

  .aboutUsSlide__paragraph-item__line {
    height: 100%;
    width: 2px;
    border-radius: 8px;
    background: rgba(232, 237, 243, 0.4);
    margin-left: 39px;
  }

  .aboutUsSlide__arrowDown {
    background: url("../../../Media/AboutUsSlideArrowDownWhite.svg") center
      no-repeat;
    background-size: contain;
    width: 24px;
    height: 12px;
    margin-top: 10vh;
  }

  .aboutUsSlide__arrowDown-black {
    background: url("../../../Media/AboutUsArrowDownBlack.svg") center no-repeat;
    background-size: contain;
    width: 24px;
    height: 12px;
    margin-top: 10vh;
  }
  .aboutUsSlide__animationTitle {
    animation: textBottomSmall .5s ease 1s 1 normal forwards;
    opacity: 0;
  }
  .aboutUsSlide__animationText {
    animation: textBottomSmall .5s ease 1.2s 1 normal forwards;
    opacity: 0;
  }
  .aboutUsSlide__animationArrow {
    animation: textBottomSmall .5s ease 1.3s 1 normal forwards;
    opacity: 0;
  }
  @keyframes textBottomSmall {
    0% {
      position: relative;
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      position: static;
      transform: translateY(0);
      opacity: 1;
    }
  }
}
