@media screen and (min-width: 400px) and (max-width: 599px) {
  .topBanner__section {
    position: relative;
    overflow: hidden;
  }
  .topBanner {
    width: 100%;
    padding: 0 35px 0 40px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 140px);
  }
  .topBanner__container {
    width: 100%;
    position: relative;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }
  .topBanner__text {
    z-index: 2;
    margin: 48px auto 40px 0;
    max-width: 355px;
  }
  .topBanner__rectangles {
    order: 3;
    display: flex;
    margin: 85px 0 0;
    height: 330px;
    width: fit-content;
  }
  .topBanner__rectangle {
    width: 128px;
    height: 304.29px;
    background-color: #e8edf3;
    transition: background-color linear 0.6s;
  }
  .rightRectangle {
    transform: rotate(7deg) translate(5px, 6px);
  }
  .centerRectangle {
    transform: rotate(-9deg) translate(6px, -21px);
  }
  .leftRectangle {
    transform: rotate(7deg) translateY(6px);
  }
  .violet {
    background-color: #512cb7;
  }
  .orange {
    background-color: #ff4324;
  }
  .pink {
    background-color: #ffc7cb;
  }

  .topBanner__btn-block {
    background: url("../../../Media/ArrowRight.svg") center left no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 11px);
    max-width: 400px;
    height: 53px;
    background-size: 74px;
    margin-right: auto;
  }

.topBanner__clients {
    /* position: relative; */
    width: 100%;
    height: 70px;
    background-color: #454545;
    display: flex;
    align-items: center;
    column-gap: 45px;
    overflow: scroll;
    /* overflow: hidden; */
    padding: 0 5%;
    box-sizing: border-box;
    /* touch-action: none; */
    pointer-events: none;
    bottom: 0;
    z-index: 3;
    /* scroll-behavior: smooth; */
    /* animation: scrollClients 20s ease-in-out 0s infinite alternate forwards; */
    /* -webkit-animation: webkit-scrollClients 20s ease-in-out 0s infinite alternate forwards;  */
    /* -moz-animation: moz-scrollClients 20s ease-in-out 0s infinite alternate forwards; */
    /* -o-animation: o-scrollClients 20s ease-in-out 0s infinite alternate forwards; */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .topBanner__clients::-webkit-scrollbar {
    display: none;
  }
  .topBanner__clients.hidden {
    opacity: 0;
  }

  .topBanner__clients-items {
    flex-shrink: 0;
    display: flex;
    gap: 45px;
    justify-content: space-around;
  }

  .marquee {
    animation: scrollClients 30s linear infinite;
  }

  .topBanner__clientLogo {
    /* height: 100%; */
    display: block;
    height: 70px;
    transition: all 0.1s ease-in-out;
  }
  
  @keyframes scrollClients {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 45px));
    }
  }

}

