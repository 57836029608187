@media screen and (min-width: 1900px) {
  .contactUs {
    display: flex;
    padding: 111px 168px 118px 100px;
    justify-content: space-between;
  }
  .contactUs__contactZone {
    width: 61.2%;
    max-width: 746px;
    padding-right: 120px;
  }
  .contactUs__formContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 100px;
    justify-content: space-between;
  }
  .contactUs__input {
    width: calc(50% - 10px);
  }
  .contactUs__contacts {
    max-width: 509px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 0;
  }
}
