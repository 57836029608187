@keyframes aboutUsTopBannerPinkAndViolet {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes aboutUsTopBannerRed {
  0% {
    transform: rotate(-15deg) translate(50px, -34px);
  }
  50% {
    transform: rotate(-92deg) translate(0);
  }
  99% {
    opacity: 1;
    transform: rotate(-92deg) translate(0);
  }
  100% {
    opacity: 0;
    transform: rotate(-92deg) translate(0);
  }
}
@keyframes aboutUsTopBannerSpan {
  0% {
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
  25% {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
  50% {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: scale(0.4) translate(0);
    top: 0%;
    left: -30%;
  }
}
@keyframes aboutUsTopBannerText {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
