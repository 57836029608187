@import url("./TextInsightBlock_1920.css");
@import url("./TextInsightBlock_1000.css");
@import url("./TextInsightBlock_mobile.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .textInsightBlock__container {
    display: flex;
    column-gap: 65px;
    padding: 42px 0;
    border-bottom: rgba(232, 237, 243, 1) solid 2px;
  }
  .textInsightBlock__cover-container {
    width: 388px;
    /* height: 325px; */
    overflow: hidden;
  }
  .textInsightBlock__content-container {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    width: 63%;
  }
  .textInsightBlock__section-container {
    width: fit-content;
    display: flex;
    column-gap: 20px;
    display: "flex";
    justify-content: "space-between";
  }
  .textInsightBlock__section-container > div {
    font-size: 20px;
    /* margin: 10px 0 0px; */
    padding-left: 23px;
    background-size: 22px;
  }
  .textInsightBlock__section {
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
  }
  .textInsightBlock__title {
    width: 540px;
  }
  .textInsightBlock__text {
    margin-top: 7px;
    font-size: 23px;
    line-height: 150%;
  }
  .textInsightBlock__infoContainer {
    margin-top: 27px;
    display: flex;
    column-gap: 43px;
    font-size: 20px;
    line-height: 120%;
    color: #949494;
    align-items: flex-end;
  }
}
