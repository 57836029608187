@media screen and (min-width: 320px) and (max-width: 949px) {

  .seeAlso {
    padding: 0px 40px 46px;
    box-sizing: border-box;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
  }
  .seeAlso__btnsContainer {
    display: flex;
    row-gap: 20px;
    /* align-items: flex-end; */
    flex-direction: column;
    padding-top: 33px;
  }
  .seeAlso__nextService {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") 93%
      center no-repeat;
    background-size: 41px;
    width: 100%;
    border: 2px solid #454545;
    border-radius: 6px;
    min-height: 59px;
    display: flex;
    align-items: center;
    font-size: 16px !important;
    padding: 7px 78px 7px 24px;
    box-sizing: border-box;
  }
  .seeAlso__goUp {
    background: url("../../../Media/GoUpArrow.svg") 93% center no-repeat;
    background-size: 41px;
    width: 100%;
    border: 2px solid #454545;
    border-radius: 6px;
    height: 59px;
    display: flex;
    align-items: center;
    font-size: 16px !important;
    padding: 7px 78px 7px 24px;
    box-sizing: border-box;
  }
  .seeAlso > div > p {
    font-size: 25px;

  }
}
