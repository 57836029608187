@media screen and (min-width: 1900px) {
  .textInsightBlock__container {
    display: flex;
    column-gap: 87px;
    padding: 55px 0;
    border-bottom: rgba(232, 237, 243, 1) solid 2px;
  }
  .textInsightBlock__cover-container {
    width: 516px;
    overflow: hidden;
  }
  .textInsightBlock__content-container {
    display: flex;
    flex-direction: column;
    row-gap: 21px;
    width: 63%;
  }
  .textInsightBlock__section-container {
    width: fit-content;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: baseline;
  }
  .textInsightBlock__section{
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
  }
  .textInsightBlock__section-container > div {
    font-size: 20px;
    padding-left: 23px;
    background-size: 22px;
  }
  .textInsightBlock__title {
    width: calc(100% - 115px);
  }
  .textInsightBlock__text {
    margin-top: 11px;
    font-size: 28px;
    line-height: 150%;
    width: calc(100% - 98px);
  }
  .textInsightBlock__infoContainer {
    margin-top: 44px;
    display: flex;
    column-gap: 43px;
    font-size: 26px;
    line-height: 120%;
    color: #949494;
    align-items: flex-end;
  }
  .textInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;

    margin-top: auto;
    color: rgba(69, 69, 69, 1);
    width: 223px;
    background-size: 56px;
    font-size: 26px;
    line-height: 120%;
    box-sizing: border-box;
  }
}
