@media screen and (min-width: 1900px) {

  .facilitiesBlock__arrow {
    position: absolute;
    bottom: 4px;
    right: -140px;
    background: url("../../../Media/ArrowRight.svg") center no-repeat;
    width: 62px;
    height: 45px;
    background-size: contain;
    transform: rotate(90deg);
    transition: transform 0.5s ease-in;
    cursor: pointer;
  }
  .facilitiesBlock__arrow.rotated {
    transform: rotate(270deg);
  }
  .facilitiesBlock {
    display: flex;
    row-gap: 22px;
    column-gap: 22px;
    flex-wrap: wrap;
    position: relative;
  }
  .facility {
    font-size: 25px;
    line-height: 34px;
    display: flex;
    align-items: center;

    font-feature-settings: "salt" on, "liga" off;
    color: #454545;
    border: 2px solid #454545;
    border-radius: 6px;
    padding: 12px 28px;
    transition: all ease 0.3s;
    box-sizing: border-box;
  }
  .facility:hover {
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
  }
  .facility_focused{
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
  }
}