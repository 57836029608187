@media screen and (min-width: 320px) and (max-width: 419px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #18191F;
    color: #949494;
    width: 100%;
    padding: 59px 28px 74px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
  }
  .footer__links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 8px;
  }
  .footer__tabs {
    color: white;
    font-size: 17px;
    line-height: 118%;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }
  .footer__socialMedia {
    display: flex;
    column-gap: 20px;
    height: 32px;
    margin: 42px 0 38px;
  }
}
