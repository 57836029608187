@media screen and (min-width: 950px) and (max-width: 1479px) {
  .aboutUsSlide__section {
    width: 100%;
    background-color: #fff;
    height: calc(100vh + 1px);
    position: absolute;
  }
  .aboutUsSlide {
    width: 100%;
    height: 100vh;
    padding: 109px 48px 109px 97px;
  }

  .aboutUsSlide-bgBlue {
    background: url("../../../Media/ExpertiseBg.png") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
  }

  .aboutUsSlide-bgWhite {
    background: url("../../../Media/ExperienceBg.jpg") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
  }

  .aboutUsSlide-bgRed {
    background: url("../../../Media/AdvantageBg.png") center no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
  }

  .aboutUsSlide__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .aboutUsSlide__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .aboutUsSlide__text h2 {
    font-size: 38px;
    font-weight: 400;
    max-width: 533px;
    line-height: 110%;
    margin-bottom: 63px;
  }

  .aboutUsSlide__text p {
    font-size: 23px;
    font-weight: 400;
    max-width: 546px;
    line-height: 150%;
  }

  .aboutUsSlide__paragraph {
    display: flex;
    flex-direction: column;
    row-gap: 39px;
  }

  .aboutUsSlide__paragraph-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  .aboutUsSlide__paragraph-item p {
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    color: rgba(232, 237, 243, 0.4);
  }

  .aboutUsSlide__paragraph-item__line {
    height: 100%;
    width: 2px;
    border-radius: 8px;
    background: rgba(232, 237, 243, 0.4);
    margin-left: 39px;
  }
}
