@media screen and (min-width: 320px) and (max-width: 949px) {

.notFound {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  height: calc(100vh - 70px);
}
.notFound__rectangles {
  background: url("../../Media/Error404.svg") center / contain no-repeat;
  width: 70%;
  height: 50%;
  margin-bottom: 5%;
}
.notFound__text {
  font-size: 18px;
  line-height: 120%;
  max-width: 70%;
  text-align: center;
}
}