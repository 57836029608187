@media screen and (min-width: 1900px) {
  .needs {
    display: flex;
    padding: 93px 168px 122px 100px;
    justify-content: space-between;
    column-gap: 250px;
  }
  .needs__content-container {
    width: 65%;
    /* max-width: 1020px; */
    /* padding-right: 250px; */
  }
  .needs__title {
    font-weight: 700;
  }
  .needs__text {
    padding: 50px 0 157px;
    width: 1020px;
  }
  .needs__needs-container{
    padding: 43px 0 0;
  }
  .needs__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 675px;
  }
  .needs__info-accent {
    font-size: 100px;
    font-weight: 700;
    line-height: 91.5%;
    font-feature-settings: "salt" on, "liga" off;
    padding-bottom: 20px;
  }
  .needs__info-block {
    display: flex;
    flex-direction: column;
  }
}
