@media screen and (min-width: 1900px) {
  .relatedProjects {
    padding: 55px 0 55px;
    box-sizing: border-box;
    display: flex;
    row-gap: 30px;
    flex-direction: column;
    overflow: hidden;
  }
  .relatedProjects > h2,
  .relatedProjects > h3 {
    padding-left: 133px;
  }
  .relatedProjects__projects {
    display: flex;
    overflow: scroll;
    column-gap: 5px;
    padding: 25px 133px 0;
  }
  .relatedProjects__projects > .projectBlock {
    width: 44vw;
    flex-shrink: 0;
  }
  .relatedProjects__projects::-webkit-scrollbar {
    display: none;
  }
  .relatedProjects__project {
    width: calc(100vw / 2 - 134px);
    height: calc((100vw / 2 - 127px) / 1.5);
  }
}
