@media screen and (min-width: 1900px) {

.connectToHead{
    padding: 82px 133px;
    box-sizing: border-box;
}
.connectToHead__container{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 0px 0 126px;
    background-color: rgba(170, 188, 210, 0.27);
    box-sizing: border-box;
    overflow: hidden;
    /* align-items: flex-end; */
    justify-content: space-around;
}
.connectToHead__text{
    display: flex;
    flex-direction: column;
    row-gap: 61px;
    padding: 224px 0 172px;

    width: 1025px;
}
.connectToHead__container > img{
    width: 100%;
    align-self: flex-end;
    max-width: 551px;
}
}