@import url("./Needs_1920.css");
@import url("./Needs_1000.css");
@import url("./Needs_700.css");
@import url("./Needs_428.css");
@import url("./Needs_375.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .needs {
    display: flex;
    padding: 93px 126px 122px 100px;
    justify-content: space-between;
    column-gap: 230px;
  }
  .needs__content-container {
    width: 62%;
  }
  .needs__title {
    font-weight: 700;
  }
  .needs__text {
    padding: 40px 0 62px;
    width: 722px;
  }
  .needs__needs-container{
    padding: 33px 0 0;
  }
  .needs__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 480px;
  }
  .needs__info-accent {
    font-size: 74px;
    font-weight: 700;
    line-height: 91.5%;
    font-feature-settings: "salt" on, "liga" off;
    padding-bottom: 20px;
  }

}
