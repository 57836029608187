@media screen and (min-width: 320px) and (max-width: 1149px) {
  .specialistPopup {
    background-color: white;
    position: fixed;
    inset: 0;
    overflow-y: scroll;
    z-index: 2;
    animation: opening 1s ease 0s 1 forwards;
  }
  .specialistPopup__content {
    display: flex;
    column-gap: 170px;
    box-sizing: border-box;
    padding: 88px 40px 67px;
    flex-direction: column;
    align-items: center;
  }
  /* .specialistPopup__employee{
    display: flex;
    flex-direction: column;
  } */
  .specialistPopup__closeBtn {
    position: absolute;
    background: url("../../../../Media/CloseBtnCrossBlack.svg") center / contain
      no-repeat;
    width: 24px;
    height: 24px;
    top: 30px;
    right: 28px;
  }
  .specialistPopup__photoContainer {
    width: 348px;
    height: 348px;
    background-color: #e8edf3;
    display: flex;
    align-items: flex-end;
    margin-bottom: 34px;
    /* align-self: center; */
  }
  .specialistPopup__photoContainer > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .specialistPopup__name {
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    margin-bottom: 11px;
    max-width: 348px;
  }
  .specialistPopup__jobTitle {
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 45px;
    max-width: 348px;
  }
  .specialistPopup__connection {
    background: url("../../../../Media/ArrowRight.svg") left center / contain
      no-repeat;
    padding-left: 115px;
  }
  .specialistPopup__paragraphs {
    display: flex;
    flex-direction: column;
    row-gap: 39px;
    margin-bottom: 45px;
  }
  .specialistPopup__paragraphs > p {
    font-size: 17px;
    line-height: 150%;
  }
  .specialistPopup__mainServices > span {
    font-size: 25px;
  }
  .specialistPopup__services {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
    margin: 42px -15px 45px 0;
  }
}
@keyframes opening {
  0% {
position: fixed;
top: 0;
left: 10%;
right: 40px;
height: 400px;
width: 80vw;
overflow: hidden;
  }
  100% {
inset: 0;
width: 100%;
height: 100%;

  }
}