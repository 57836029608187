@media screen and (min-width: 320px) and (max-width: 399px) {
  .workWithUs {
    display: flex;
    padding: 48px 30px 30px;
    box-sizing: border-box;
  }
  .workWithUs__title {
    padding: 29px 0 calc(100vw * 1.2) 0;
    font-size: 32px;
    line-height: 110%;
    background: url("../../../Media/WorkWithUsPhotoMobile.png") center 142px/100%
      no-repeat;
  }
  .workWithUs__btnsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
