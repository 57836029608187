@media screen and (min-width: 600px) and (max-width: 949px) {
  .workWithUs {
    display: flex;
  }
  .workWithUs__content {
    padding: 50px 50px 81px;
    width: 100%;
    background: url("../../../Media/WorkWithUsPhoto.png") calc(100% + 93px) no-repeat;
    background-size: contain;
  }
  .workWithUs__title {
    padding: 32px 0 30px;
    font-size: 32px;
    width: 282px;
  }
  .workWithUs__btnsContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
    transform: translateX(-8px);
  }
}
