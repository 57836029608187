@import url("./RelatedProjects_1920.css");
@import url("./RelatedProjects_1000.css");
@import url("./RelatedProjects_mobile.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .relatedProjects {
    padding: 42px 0 42px;
    box-sizing: border-box;
    display: flex;
    row-gap: 22px;
    flex-direction: column;
    overflow: hidden;
  }
  .relatedProjects > h2,
  .relatedProjects > h3 {
    padding-left: 100px;
  }
  .relatedProjects__projects {
    display: flex;
    overflow: scroll;
    column-gap: 4px;
    padding: 19px 100px 0;
  }
  .relatedProjects__projects > .projectBlock {
    width: 44vw;
    flex-shrink: 0;
  }
  .relatedProjects__projects::-webkit-scrollbar {
    display: none;
  }
  .relatedProjects__project {
    width: calc(100vw / 2 - 104px);
    height: calc((100vw / 2 - 94px) / 1.5);
  }
}
