@import url("./Footer_1920.css");
@import url("./Footer_1000.css");
@import url("./Footer_700.css");
@import url("./Footer_428.css");
@import url("./Footer_375.css");


@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232520;
    color: #949494;
    width: 100%;
    padding: 103px 0 88px;
    box-sizing: border-box;
  }
  .footer__links {
    width: 53%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 19px;
  }
  .footer__tabs {
    color: white;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 64px;
  }
  .footer__socialMedia {
    display: flex;
    column-gap: 20px;
    margin: 48px 0 0px;
    justify-content: center;
  }
  .footer__copyright {
    font-size: 20px;
  }
}
