@import url("./ContactUs_1920.css");
@import url("./ContactUs_1000.css");
@import url("./ContactUs_700.css");
@import url("./ContactUs_428.css");
@import url("./ContactUs_375.css");



@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .contactUs {
    display: flex;
    padding: 111px 168px 118px 100px;
    justify-content: space-between;
  }
  .contactUs__contactZone {
    width: 61.2%;
    max-width: 746px;
    padding-right: 120px;
  }
  .contactUs__formContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 40px;
    justify-content: space-between;
  }
  .contactUs__input {
    width: calc(50% - 10px);
  }
  .contactUs__contacts {
    max-width: 334px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
  }
}
