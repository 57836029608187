@media screen and (min-width: 320px) and (max-width: 949px) {
  .textInsightBlock__container {
    display: flex;
    flex-direction: column;
    margin: 41px 20px 0;
    border-bottom: rgba(232, 237, 243, 1) solid 2px;
  }
  .textInsightBlock__cover-container {
    width: 100%;
    height: 445px;
    overflow: hidden;
    position: relative;
  }
  .textInsightBlock__coverRecommendation {
    width: 153px;
    height: 43px;
    border-radius: 7px;
    background: rgba(232, 237, 243, 0.6);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 23px;
    bottom: 22px;
  }
  .textInsightBlock__content-container {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    padding: 32px 20px 36px;
  }
  .textInsightBlock__section-container {
    width: fit-content;
    display: flex;
    align-items: baseline;
  }
  .textInsightBlock__section {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
  }
  .textInsightBlock__section-container > div {
    margin: 7px 0 0 29px;
  }
  .textInsightBlock__title {
    width: calc(100% - 50px);
  }
  .textInsightBlock__text {
    margin-top: 7px;
    font-size: 17px;
    line-height: 150%;
    margin-bottom: 25px;
  }
  .textInsightBlock__infoContainer {
    margin-top: 44px;
    display: flex;
    column-gap: 43px;
    font-size: 16px;
    line-height: 120%;
    color: #949494;
    align-items: center;
    justify-content: space-between;
  }
  /* .textInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    color: rgba(69, 69, 69, 1);
    width: 170px;
    background-size: 41px;
    box-sizing: border-box;
    position: relative;
  } */
  .textInsightBlock__content-container > a > div {
    font-size: 16px;
    width: 143px;
  }
}
