@media screen and (min-width: 680px) and (max-width: 949px) {
  .needs {
    display: flex;
    padding: 65px 70px 89px 50px;
    justify-content: space-between;
  }
  .needs__content-container {
    width: 60%;
    padding-right: 31px;
  }
  .needs__content-container > div {
    padding-bottom: 35px;
    display: flex;
    column-gap: 31px;
    align-items: center;
  }
  .needs__content-container > div > span {
    display: flex;
    font-size: 16px;
  }
  .needs__mobileArrow {
    background: url("../../../Media/ArrowDownBlueRoundCorners.svg") center
      no-repeat;
    width: 13px;
    height: 36px;
    flex-shrink: 0;
  }
  .needs__title {
    font-weight: 700;
  }
  .needs__text {
    padding: 22px 0 35px;
  }
  .needs__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 440px;
  }
  .needs__info-accent {
    font-size: 64px;
    font-weight: 700;
    line-height: 91.5%;
    font-feature-settings: "salt" on, "liga" off;
    padding-bottom: 20px;
  }
  .needs__info-block {
    display: flex;
    flex-direction: column;
  }
}
