@media screen and (min-width: 400px) and (max-width: 679px) {
  .needs {
    display: flex;
    padding: 63px 0 78px;
    row-gap: 73px;
    flex-direction: column;
  }
  .needs__content-container {
    width: 100%;
    padding: 0 35px 0 40px;
    order: 2;
    box-sizing: border-box;
  }
  .needs__content-container > div {
    padding-bottom: 35px;
    display: flex;
    column-gap: 31px;
    align-items: flex-end;
  }
  .needs__content-container > div > span {
    font-size: 16px;
    line-height: 120%;
    display: flex;
    max-width: 249px;

  }
  .needs__mobileArrow {
    background: url("../../../Media/ArrowDownBlueRoundCorners.svg") center
      no-repeat;
    width: 13px;
    height: 36px;
    flex-shrink: 0;
  }
  .needs__title {
    font-weight: 700;
  }
  .needs__text {
    padding: 22px 0 38px;
  }
  .needs__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 390px;
    width: 100%;
    background: url("../../../Media/NeedsPhotoMobile.png") right center
      no-repeat;
    background-size: contain;
    padding-left: 40px;
    box-sizing: border-box;
  }
  .needs__info-accent {
    font-size: 64px;
    font-weight: 700;
    line-height: 91.5%;
    font-feature-settings: "salt" on, "liga" off;
    padding-bottom: 10px;
  }
  .needs__info-block {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
}
