@media screen and (min-width: 950px) and (max-width: 1479px) {
.facilitiesBlock {
  display: flex;
  row-gap: 20px;
  column-gap: 20px;
  flex-wrap: wrap;
  position: relative;
}
.facility {
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  font-feature-settings: "salt" on, "liga" off;
  color: #454545;
  border: 2px solid #454545;
  border-radius: 6px;
  padding: 8px 22px;
  transition: all ease 0.3s;
  box-sizing: border-box;
}
.facility:hover {
  color: #fff;
  border-color: #ff4324;
  background-color: #ff4324;
  cursor: pointer;
}
.facility_focused{
  color: #fff;
  border-color: #ff4324;
  background-color: #ff4324;
  cursor: pointer;
}
}