@media screen and (min-width: 801px) and (max-width: 1479px) {
    .specialistCard {
        display: flex;
        flex-direction: column;
        width: 38vw;
        flex-shrink: 0;
    }
    .specialistCard__inf {
        padding-top: 33px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;
      }
    .specialistCard__inf > div:hover {
        color: #ffffff;
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(58deg)
          brightness(105%) contrast(101%);
      }
      .specialistCard__imageContainer {
        height: 38vw;
        width: 100%;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        background-color: #e8edf3;

      }
      .specialistCard__imageContainer img {
        object-fit: contain;
        object-position: bottom;
        height: 100%;
        width: 100%;
        transform: translateY(1px);
      }
    
    .specialistCard h4 {
        color: #18191F;
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        margin-bottom: 11px;
    }

    .specialistCard p {
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        width: 100%;
        margin-bottom: 57px;
    }
}