@font-face {
  font-family: "Pragmatica";
  src: local("Pragmatica Book"), local("Pragmatica-Book"),
    url("Pragmatica-Book.woff2") format("woff2"),
    url("Pragmatica-Book.woff") format("woff"),
    url("Pragmatica-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pragmatica";
  src: local("Pragmatica Bold"), local("Pragmatica-Bold"),
    url("Pragmatica-Bold.woff2") format("woff2"),
    url("Pragmatica-Bold.woff") format("woff"),
    url("Pragmatica-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Pragmatica";
  src: local("Pragmatica Light"), local("Pragmatica-Light"),
    url("Pragmatica-Light.woff2") format("woff2"),
    url("Pragmatica-Light.woff") format("woff"),
    url("Pragmatica-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
