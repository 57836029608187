@media screen and (min-width: 950px) and (max-width: 1479px) {
 
  .projectBlock {
    width: calc(50% - 2px);
    height: calc((50vw - 2px) / 1.5);
    overflow: hidden;
    position: relative;
  }
  
  .projectBlock_hovered {
    backdrop-filter: blur(5px);
    padding: 30px 40px 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 10, 6, 0.5);
    opacity: 0;
    transition: opacity ease-in .5s;
    cursor: pointer;
  }
  .projectBlock_hovered.visible {
    opacity: 1;
  }
  .projectBlock__rectangle_hovered {
    width: 22.29px;
    height: 53.22px;
    background: #ffffff;
    transform: rotate(-6.54deg);
    margin-right: 19px;
    flex-shrink: 0;
  }
  .projectBlock__title_hovered {
    font-size: 40px;
    font-weight: 700;
    font-feature-settings: "salt" on, "liga" off;
    color: #ffffff;
    display: flex;
    align-items: center;
  }
  .projectBlock__text_hovered {
    width: 90%;
    max-width: 430px;
    color: white;
    margin-top: 29px;
  }
  .projectBlock__button_hovered {
    background: url("../../../Media/ArrowRigthWhiteRoundCorners.svg") right
      center no-repeat;
    padding-right: 62px;
    margin-top: auto;
    color: white;
    width: 170px;
    background-size: 42px;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: "salt" on, "liga" off;
    box-sizing: border-box;
  }
}
