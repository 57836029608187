@media screen and (min-width: 420px) and (max-width: 599px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #18191F;
    color: #949494;
    width: 100%;
    padding: 59px 45px 69px;
    box-sizing: border-box;
    font-size: 16px;
  }
  .footer__links {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .footer__tabs {
    color: white;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
  }
  .footer__socialMedia {
    display: flex;
    column-gap: 20px;
    margin: 49px 0;
    height: 37px;
  }
  .footer__copyright {
    font-size: 17px;
    padding-top: 20px;
  }
}
