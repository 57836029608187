@media screen and (min-width: 320px) and (max-width: 949px) {
  .relatedProjects {
    padding: 46px 0 0px;
    box-sizing: border-box;
    display: flex;
    row-gap: 19px;
    flex-direction: column;
    overflow: hidden;
  }
  .relatedProjects > h3 {
    font-size: 16px;
    padding-left: 40px;
  }
  .relatedProjects > h2 {
    padding: 0 30px 0 40px;
    /* width: 320px; */
  }
  .relatedProjects__projects > a {
    display: flex;
  }
  .relatedProjects__projects {
    display: flex;
    overflow: scroll;
    column-gap: 20px;
    align-items: stretch;
    padding: 18px 40px 0;
  }
  .relatedProjects__projects::-webkit-scrollbar {
    display: none;
  }
  .relatedProjects__project {
    width: calc(100vw / 1.9 - 6px);
    height: calc((100vw / 3) + 16px);
  }
  .relatedProjects__projects > .projectBlock {
    width: 77vw;
    height: auto;
    flex-shrink: 0;
  }
}
