@import url("./SeeAlso_1920.css");
@import url("./SeeAlso_1000.css");
@import url("./SeeAlso_mobile.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .seeAlso {
    padding: 42px 100px 103px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .seeAlso__btnsContainer {
    display: flex;
    row-gap: 20px;
    align-items: baseline;
    flex-direction: column;
    align-items: flex-end;
  }
  .seeAlso__nextService {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    background-size: 42px;
    padding-right: 62px;
    cursor: pointer;
  }
  .seeAlso__goUp {
    background: url("../../../Media/GoUpArrow.svg") right top no-repeat;
    background-size: 42px;
    padding-right: 62px;
    cursor: pointer;
  }
}
