@import url("./TimeToRead_1920.css");
@import url("./TimeToRead_1000.css");
@import url("./TimeToRead_700.css");
@import url("./TimeToRead_428.css");
@import url("./TimeToRead_375.css");

@media screen and (min-width: 1480px) and (max-width: 1899px) {
  .time__toListen {
    background: url("../../../Media/TimeToListenDarkIcon.svg") left center
      no-repeat;
    padding-left: 18px;
    margin: 3px 0 0px 0;
    font-size: 15px;
    line-height: 120%;
    color: rgba(148, 148, 148, 1);
  }
  .time__toRead {
    background: url("../../../Media/TimeToReadDarkIcon.svg") left top no-repeat;
    padding-left: 18px;
    /* margin: 3px 0 0px 0; */
    font-size: 15px;
    line-height: 120%;
    color: rgba(148, 148, 148, 1);
  }
  .time__toListen.timelight {
    background: url("../../../Media/TimeToListenLightIcon.svg") left center
      no-repeat;
    color: #e8edf3;
    margin: 3px 0 12px 29px;
  }
  .time__toRead.timelight {
    margin: 3px 0 12px 29px;
    background: url("../../../Media/TimeToReadLightIcon.svg") left center no-repeat;
    color: #e8edf3;
  }
}
