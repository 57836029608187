@media screen and (min-width: 1900px) {
  .serviceDescription {
    padding: 0px 100px 38px;
    box-sizing: border-box;
    display: flex;
    column-gap: 21px;
  }
  .serviceDescription__description {
    background: #e8edf3;
    border-radius: 20px;
    padding: 95px 83px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 36px;
    box-sizing: border-box;
    width: calc(100% - 622px);
  }
  .serviceDescription__description > h3 {
    color: #a1a1a1;
  }
  .serviceDescription__description > h2 {
font-size: 48px;
  }
  .serviceDescription__description > p {
    font-size: 28px;
    line-height: 140%;
  }
  .serviceDescription__decoration {
    width: 601px;
    height: auto;
    border-radius: 20px;
    flex-shrink: 0;
    min-height: 451px;
    object-fit: cover;
  }
}
