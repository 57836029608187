@media screen and (min-width: 600px) and (max-width: 949px) {
  .ourInsights {
    padding: 77px 50px 55px 50px;
    box-sizing: border-box;
  }
  .ourInsights__container {
    margin-top: 45px;
    display: flex;
    column-gap: 5%;
    justify-content: space-between;
  }
  .ourInsights__coverContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .ourInsights__coverContainer > a {
    flex-direction: column;
    height: 100%;
  }
  .ourInsights__textContainer {
    background-color: rgba(24, 25, 31, 1);
    padding: 23px 15px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .ourInsights__textContainer > p {
    color: white;
    font-size: 23px;
  }
  .ourInsights__rightContainer {
    min-width: 295px;
    display: flex;
    flex-direction: column;
  }
  .ourInsights__insightBlock {
    margin-bottom: 41px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .ourInsights__insightBlock > a {
    flex-direction: column;
  }
  .ourInsights__insightBlock > a > p {
    font-size: 20px;
  }
  .ourInsights__titleContainer{
    width: 205px;
    justify-content: space-between;
  }
  .ourInsights__section {
    margin-bottom: 16px;
  }
  .ourInsights__moreBtn {
    width: 191px;
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    font-size: 16px;
    cursor: pointer;
  }
 
}
