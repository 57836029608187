@media screen and (min-width: 1900px) {
  .ourInsights {
    padding: 78px 170px 118px 100px;
    box-sizing: border-box;
  }
  .ourInsights__container {
    margin-top: 55px;
    display: flex;
    column-gap: 159px;
  }
  .ourInsights__coverContainer {
    width: 63%;
    cursor: pointer;
    display: flex;
    position: relative;
  }
  .ourInsights__coverShadow {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      179.9deg,
      rgba(24, 25, 31, 0) 55.77%,
      rgba(24, 25, 31, 0.410548) 72.46%,
      #18191f 105.83%
    );
    position: absolute;
    inset: 0;
  }
  .ourInsights__textContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    margin: 74px 70px;
    display: flex;
    flex-direction: column;
  }
  .ourInsights__textContainer > p {
    font-weight: 700;
    color: #fff;
  }
  .ourInsights__rightContainer {
    width: 29%;
    max-width: 443px;
    display: flex;
    flex-direction: column;
  }
  .ourInsights__insightBlock {
    margin-bottom: 55px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
  }
  .ourInsights__titleContainer {
    width: 265px;
    justify-content: space-between;
    align-items: baseline;
  }
  .ourInsights__section {
    margin-bottom: 16px;
  }
  .ourInsights__moreBtn {
    width: 272px;
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    font-size: 25px;
    cursor: pointer;
  }
}
