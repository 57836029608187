@import url("./AboutUsScroll_mobile.css");

@media screen and (min-width: 950px) {
  .scroll__parentContainer {
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  .scroll__container::-webkit-scrollbar,
  body::-webkit-scrollbar,
  .AboutUs::-webkit-scrollbar {
    display: none;
  }
  .scroll__child {
    background-color: white;
    width: 100%;
  }
  .child__container {
    position: absolute;
    inset: 0;
    height: 100vh;
    width: 100%;
  }
  .hidden:not(.current, .before)  {
    opacity: 0;
  }
  .current {
    /* opacity: 1; */
    z-index: 5;
  }
  .current:not(.scrollDown, .scrollUp) {
    opacity: 1;
  }
  .current_visible{
opacity: 1;
  }
  .before {
    opacity: 1;
    z-index: 4;
  }
  /* animation block */
  .scrollDown.current {
    animation: slidingUp 0.6s ease 0s 1 normal;
    animation-fill-mode: forwards;

    /* opacity: 0; */
  }
  .scrollUp.current {
    animation: slidingDown 0.4s ease 0s 1 normal;
    animation-fill-mode: forwards;
    /* opacity: 0; */
  }
  .scrollDown.before {
    animation: slidingFromDown 0.6s ease 0s 1 normal forwards;
  }
  .scrollUp.before {
    animation: slidingFromTop 0.4s ease 0s 1 normal forwards;
  }
  @keyframes slidingUp {
    0% {
      transform: translateY(60%);
      opacity: 0;

    }
    1% {
      opacity: 1;
      transform: translateY(60%);
    }
    100% {
      opacity: 1 !important;
      transform: translateY(0);
    }
  }
  @keyframes slidingDown {
    0% {
      transform: translateY(-100%);
    }
    1% {
      opacity: 1;
      transform: translateY(-100%);
      /* opacity: 1 !important; */
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slidingFromTop {
    0% {
      opacity: 1;

      transform: translateY(0);
    }

    100% {
      opacity: 1;

      transform: translateY(60%);
    }
  }
  @keyframes slidingFromDown {
    0% {
      z-index: 6;
      opacity: 1;

      transform: translateY(0);
    }
    100% {
      z-index: 6;
      opacity: 1;

      transform: translateY(-100%);
    }
  }
}
