@media screen and (min-width: 950px) and (max-width: 1479px) {
  .contactUs {
    display: flex;
    padding: 110px 230px 105px 50px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .contactUs__contactZone {
    width: 100%;
  }
  .contactUs__formContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 40px;
    justify-content: space-between;
  }
  .contactUs__input {
    width: calc(50% - 10px);
  }
  .contactUs__contacts {
    max-width: 716px;
    display: flex;
    column-gap: 65px;
    margin-right: auto;
    padding-top: 62px;
    align-items: center;
  }
}
