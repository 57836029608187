@media screen and (min-width: 400px) and (max-width: 599px) {
  .ourInsights {
    padding: 0px 40px 5px;
    box-sizing: border-box;
  }
  .ourInsights__container {
    margin-top: 45px;
    display: flex;
    row-gap: 22px;
    flex-direction: column;
  }
  .ourInsights__coverContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .ourInsights__coverContainer > a {
    flex-direction: column;
  }
  .ourInsights__textContainer {
    background-color: rgba(24, 25, 31, 1);
    padding: 22px 22px 19px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .ourInsights__textContainer > p {
    color: white;
    font-size: 25px;
    line-height: 105%;
    margin-top: 8px;
  }
  .ourInsights__rightContainer {
    width: 348px;
    display: flex;
    flex-direction: column;
    /* row-gap: 30px; */
  }
  .ourInsights__rightContainer > a {
    justify-content: center;
    margin-top: 46px;
  }
  .ourInsights__insightBlock {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 24px 20px 26px;
    box-sizing: border-box;
    border-bottom: 2px solid #E8EDF3;
    margin: 0 -20px;
  }
  .ourInsights__insightBlock > a > p {
    font-size: 23px;
  }
  .ourInsights__insightBlock > a {
    flex-direction: column;
  }
  .ourInsights__titleContainer {
    column-gap: 20px;
  }
  .ourInsights__section {
    margin-bottom: 10px;
  }
  .ourInsights__moreBtn {
    width: 230px;
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
  }
}
