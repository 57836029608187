@media screen and (min-width: 1900px) {
  .insightWithCover__coverShadow {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      179.9deg,
      rgba(24, 25, 31, 0) 55.77%,
      rgba(24, 25, 31, 0.410548) 72.46%,
      #18191f 105.83%
    );
    position: absolute;
    inset: 0;
  }
  .insightWithCover__textContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    margin: 69px 69px;
    display: flex;
    flex-direction: column;
  }
  .insightWithCover__textContainer > p {
    font-weight: 700;
    color: #fff;
  }
  .insightWithCover__textContainer.mainpage > div > span{
    margin-bottom: 13px;
  }
  .insightWithCover__textContainer.insightsPage > div > span{
    font-size: 30px;
    margin-bottom: 19px;
    line-height: 76%;
    
  }
  .insightWithCover__textContainer.insightsPage{
    margin: 84px 95px;

  }
  .insights__mainInsight-container_hovered {
    backdrop-filter: blur(5px);
    position: absolute;
    inset: 0;
    background: rgba(0, 10, 6, 0.7);
    color: white;
    z-index: 2;
    padding: 75px 172px 75px 70px;
    display: flex;
    flex-direction: column;
    transition: all 0.8s ease 0s;
    opacity: 0;
  }
  .insights__mainInsight-container_hovered:hover {
    opacity: 1;
  }
  .insights__mainInsight-section_hovered {
    color: rgba(232, 237, 243, 1);
    font-size: 22px;
    font-weight: 700;
  }
  .insights__mainInsight-title_hovered {
    font-size: 33px;
    padding: 15px 0 43px;
    font-weight: 700;
  }
  .insights__mainInsight-description_hovered {
    font-size: 23px;
    line-height: 150%;
  }
  .insights__mainInsight-btn_hovered {
    background: url("../../../Media/ArrowRigthWhiteRoundCorners.svg") right center
      no-repeat;
    margin-top: auto;
    color: white;
    width: 170px;
    background-size: 41px;
    font-size: 20px;
    line-height: 120%;
    font-feature-settings: "salt" on, "liga" off;
    box-sizing: border-box;
  }
}
