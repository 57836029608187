@media screen and (min-width: 400px) and (max-width: 599px) {
  .contactUs {
    display: flex;
    padding: 20px 40px 52px;
    row-gap: 37px;
    box-sizing: border-box;
    flex-direction: column;
  }
  .contactUs__contactZone {
    width: 100%;
  }
  .contactUs__formContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 40px;
  }
  .contactUs__input {
    width: 100%;
  }
  .contactUs__input:last-of-type {
    margin-bottom: 5px;
  }
  .contactUs__contacts {
    background: url("../../../Media/ArrowRight.svg") top left no-repeat;
    background-size: 74px;
    width: 248px;
    display: flex;
    flex-direction: column;
    row-gap: 21px;
    padding-top: 85px;
  }
}
