@media screen and (min-width: 400px) and (max-width: 599px) {
  .App {
    background-color: #fff;
    font-family: "Pragmatica";
    font-weight: 400;
    color: #232520;
    /* overflow: hidden; */
  }
  a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }
  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
    font-weight: 400;
  }
  h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 105%;
  }
  h2 {
    font-weight: 400;
    font-size: 28px;
  }
  p.small {
    font-size: 16px;
    line-height: 150%;
  }
  p.big {
    font-size: 28px;
    color: #18191f;
    line-height: 120%;
  }
  h3 {
    font-size: 20px; 
    color: rgba(133, 107, 205, 1);
    font-weight: 700;
  }
  span.capture {
    font-size: 20px;
  }
  span.overline {
    font-size: 16px;
    color: rgba(170, 188, 210, 1);
    font-weight: 700;
  }
  input {
    padding: 0 24px;
    border: 2px solid #454545;
    border-radius: 6px;
    color: #454545;
    height: 55px;
    font-size: 20px;
    box-sizing: border-box;
  }
  input::placeholder {
    color: #949494;
    font-size: 20px;
  }
  input:focus-visible {
    outline: none;
  }
  img {
    user-select: none;
  }
}