@media screen and (min-width: 950px) and (max-width: 1479px) {
  .workWithUs {
    display: flex;
  }
  .workWithUs__content {
    padding: 93px 0px 105px 50px;
    width: 100%;
    background: url("../../../Media/WorkWithUsPhoto.png") calc(100% + 125px) no-repeat;
    background-size: contain;
  }
  .workWithUs__title {
    padding: 30px 0 62px;
    font-size: 38px;
    width: 352px;
  }
  .workWithUs__btnsContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .workWithUs__arrow {
    background: url("../../../Media/ArrowRight.svg") center no-repeat;
    width: 74px;
    height: 54px;
    margin-right: 21px;
  }
}
