@media screen and (min-width: 320px) and (max-width: 949px) {
  .podcastInsightBlock__container {
    background-color: white;
    transform: translateY(-3px);
    position: relative;
    z-index: 2;
  }
  .podcastInsightBlock {
    background: #e8edf3;
    padding: 38px 40px 48px;
    box-sizing: border-box;
  }
  .podcastInsightBlock__text {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    height: 100%;
  }
  .podcastInsightBlock__section-container {
    width: fit-content;
    display: flex;
    column-gap: 20px;
    align-items: baseline;
  }
  .podcastInsightBlock__section {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
  }
  .podcastInsightBlock__title {
    font-size: 25px;
    margin-bottom: 18px;
  }
  .podcastInsightBlock__description {
    font-size: 17px;
    line-height: 150%;
  }
  .podcastInsightBlock__infoContainer {
    display: flex;
    column-gap: 43px;
    font-size: 16px;
    line-height: 120%;
    color: #949494;
    margin-top: 24px;
    align-items: baseline;
    justify-content: space-between;
  }
  .podcastInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;

    margin-top: 5px;
    color: rgba(69, 69, 69, 1);
    padding-right: 55px;
    background-size: 41px;
    position: relative;
  }
  .podcastInsightBlock__popupBackground {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.58);
    z-index: 2;
  }
}
