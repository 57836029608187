@media screen and (min-width: 1900px) {
.projects__top{
    display: flex;
    flex-direction: column;
    padding: 113px 371px 112px 105px;
    box-sizing: border-box;
    row-gap: 102px;
}
.projects__title{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 1010px;
}
.projects__tags{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 19px;
}
.projects__mainProject{
    width: 100%;
    height: 480px;
    background-color: rgba(72, 0, 188, .5);
}
.projects__projects{
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
}
.projects__btnContainer{
    display: flex;
    justify-content: center;
    padding-top: 52px;
}
.projects__tag{
    font-size: 25px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: "salt" on, "liga" off;
    color: #454545;
    border: 2px solid #454545;
    border-radius: 6px;
    padding: 12px 28px;
    transition: all ease 0.3s;
    box-sizing: border-box;
}
.projects__tag:hover{
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
}
.projects__tag_focused{
    color: #fff;
    border-color: #ff4324;
    background-color: #ff4324;
    cursor: pointer;
}
}