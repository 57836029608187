@media screen and (min-width: 950px) and (max-width: 1479px) {
  .textInsightBlock__container {
    display: flex;
    column-gap: 49px;
    padding: 42px 0;
    border-bottom: rgba(232, 237, 243, 1) solid 2px;

  }
  .textInsightBlock__cover-container {
    width: 388px;
    height: 445px;
    overflow: hidden;
  }
  .textInsightBlock__content-container {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    width: 53%;
    max-width: 486px;
  }
  .textInsightBlock__section {
    font-size: 22px !important;
  }
  .textInsightBlock__section-container {
    width: fit-content;
    display: flex;
    align-items: baseline;
  }
  .textInsightBlock__section{
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
  }
  .textInsightBlock__section-container > div {
    margin: 7px 0 0 29px;
  }
  .textInsightBlock__title {
    width: calc(100% - 50px);
  }
  .textInsightBlock__text {
    margin-top: 7px;
    font-size: 23px;
    line-height: 150%;
  }
  .textInsightBlock__infoContainer {
    margin-top: 44px;
    display: flex;
    column-gap: 43px;
    font-size: 20px;
    line-height: 120%;
    color: #949494;
    align-items: flex-end;
  }
  .textInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: auto;
    color: rgba(69, 69, 69, 1);
    width: 170px;
    background-size: 41px;
    box-sizing: border-box;
  }
}
