@media screen and (min-width: 1900px) {
  .aboutUsTopBanner__section {
    width: 100%;
    background-color: white;
    height: calc(100vh + 1px);
  }
  .aboutUsTopBanner {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .aboutUsTopBanner__container {
    height: 100%;
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    opacity: 0;
    background: url("../../../Media/AboutUsTopBannerDecoration.svg") right
      center / contain no-repeat;
  }
  .aboutUsTopBanner__container_animation {
    animation: aboutUsTopBannerText 1s ease 2.7s 1 normal forwards;

  }
  .aboutUs__title {
    font-size: 85px;
    font-weight: 400;
    line-height: 120%;
    max-width: 1716px;
  }

  .aboutUsTopBanner__pbn-bg {
    background: url("../../../Media/PBNBg.svg") center no-repeat;
    color: #fff;
    background-size: contain;
    padding: 10px;
  }

  .aboutUsTopBanner__rectangles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
  .aboutUsTopBanner__rectangle {
    width: 224px;
    height: 537px;
  }
  .aboutUsTopBanner__pink {
    transform: rotate(15deg);
    background-color: #ffc7cb;
    animation: aboutUsTopBannerPinkAndViolet 0.9s ease 0s 1 normal forwards;
  }
  .aboutUsTopBanner__red {
    background-color: #ff4324;
    animation: aboutUsTopBannerRed 1.2s ease 1s 1 normal forwards;
  }
  .aboutUsTopBanner__violet {
    background-color: #512cb7;
    transform: rotate(15deg);
    animation: aboutUsTopBannerPinkAndViolet 0.9s ease 0s 1 normal forwards;
  }
  .aboutUsTopBanner__withText {
    background: url("../../../Media/PBNBg.svg") center / contain no-repeat;

    color: #fff;
    font-size: 200px;
    font-weight: 400;
    line-height: 120%;
    position: absolute;
    width: 560px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: aboutUsTopBannerSpan 1.5s ease 1.6s 1 normal forwards;
    opacity: 0;
  }
}
