@media screen and (min-width: 950px) and (max-width: 1479px) {
  .connectToHead {
    padding: 33px 50px;
    box-sizing: border-box;
  }
  .connectToHead__container {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 0px 0 45px;
    background-color: rgba(170, 188, 210, 0.27);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  .connectToHead__text {
    display: flex;
    flex-direction: column;
    row-gap: 61px;
    padding: 129px 0 81px;
    max-width: 485px;
  }
  .connectToHead__container > img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}
