@media screen and (min-width: 320px) and (max-width: 949px) {

.ourServices{
    padding: 59px 0px 52px;
    box-sizing: border-box;
    display: flex;
    row-gap: 52px;
    flex-direction: column;
    background-color: #512CB7;
    color: #FFFFFF;
}
.ourServices__titles > h3 {
    color: #FFFFFF;
    font-size: 16px;
}
.ourServices__titles{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    padding-right: 51px;
    padding: 0 51px 0 40px;
}
}