@media screen and (min-width: 320px) and (max-width: 399px) {
  .ourInsights {
    padding: 0px 30px 56px;
    box-sizing: border-box;
  }
  .ourInsights__container {
    margin-top: 37px;
    display: flex;
    row-gap: 22px;
    flex-direction: column;
  }
  .ourInsights__coverContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .ourInsights__coverContainer > a {
    flex-direction: column;
  }
  .ourInsights__textContainer {
    background-color: rgba(24, 25, 31, 1);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .ourInsights__textContainer > p {
    color: white;
    font-size: 25px;
    line-height: 120%;
    margin-top: 8px;
  }
  .ourInsights__insightBlock > a {
    flex-direction: column;
  }
  .ourInsights__rightContainer {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .ourInsights__rightContainer > a {
    justify-content: center;
    margin-top: 46px;
  }
  .ourInsights__insightBlock {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 24px 20px 26px;
    box-sizing: border-box;
    border-bottom: 2px solid #e8edf3;
    margin: 0 -20px;
  }
  .ourInsights__insightBlock > a > p {
    font-size: 23px;
  }
  .ourInsights__titleContainer {
    column-gap: 20px;
  }
  .ourInsights__section {
    margin-bottom: 10px;
  }
  .ourInsights__moreBtn {
    width: 192px;
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
  }
}
