@media screen and (min-width: 950px) and (max-width: 1479px) {

  .podcastInsightBlock__container {
    background-color: white;
    transform: translateY(-3px);
    box-sizing: border-box;
    padding: 32px 0;
  }
  .podcastInsightBlock {
    background: rgba(170, 188, 210, 0.27);
    padding: 49px 45px;
    box-sizing: border-box;
  }
  .podcastInsightBlock__text {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    height: 100%;
    width: calc(100% - 80px)
  }
  .podcastInsightBlock__section-container {
    width: fit-content;
    display: flex;
    /* column-gap: 31px; */
  }
  .podcastInsightBlock__section {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
  }
  .podcastInsightBlock__description{
    font-size: 23px;
  }
  .podcastInsightBlock__infoContainer {
    display: flex;
    column-gap: 43px;
    font-size: 20px;
    line-height: 120%;
    color: #949494;
    margin-top: 30px;
    align-items: baseline;
  }
  .podcastInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;

    margin-top: 5px;
    color: rgba(69, 69, 69, 1);
    width: 245px;
    background-size: 41px;
    font-size: 20px;
    line-height: 120%;
    position: relative;

  }
}
