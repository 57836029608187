@media screen and (min-width: 1900px) {
  .seeAlso {
    padding: 57px 133px 112px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .seeAlso > div > p {
    font-size: 33px;
  }
  .seeAlso__btnsContainer {
    display: flex;
    row-gap: 20px;
    align-items: flex-end;
    flex-direction: column;
  }
  .seeAlso__btnsContainer > span {
    font-size: 26px;
  }
  .seeAlso__nextService {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    background-size: 42px;
    padding-right: 62px;
    cursor: pointer;
  }
  .seeAlso__goUp {
    background: url("../../../Media/GoUpArrow.svg") right top no-repeat;
    background-size: 42px;
    padding-right: 62px;
    cursor: pointer;
  }
}
