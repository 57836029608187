@media screen and (min-width: 950px) and (max-width: 1479px) {
    .dignityCard {
        width: 597px;
        height: 315px;
        flex-shrink: 0;
        background-color: #E8EDF3;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 70px;
        box-sizing: border-box;
    }

    .dignityCard__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        row-gap: 23px;
    }

    .dignityCard h4 {
        font-weight: 700;
        font-size: 33px;
        line-height: 130%;
    }

    .dignityCard p {
        font-size: 25px;
        line-height: 130%;
        font-weight: 400;
    }
}