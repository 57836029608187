@media screen and (min-width: 1900px) {
  .podcastInsightBlock__container {
    background-color: white;
    transform: translateY(-3px);
    box-sizing: border-box;
    padding: 31px 0;
  }
  .podcastInsightBlock {
    background: url("../../../Media/PodcastInsightCover.svg") right bottom
        no-repeat,
      rgba(170, 188, 210, 0.27);
    padding: 117px 104px;
    box-sizing: border-box;
    background-size: 637px;
  }
  .podcastInsightBlock__text {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    height: 100%;
    width: 76%;
  }
  .podcastInsightBlock__section-container {
    width: fit-content;
    display: flex;
    column-gap: 32px;
    align-items: baseline;
  }
  .podcastInsightBlock__section-container > div {
    font-size: 20px;
    padding-left: 23px;
    background-size: 16px;
  }
  .podcastInsightBlock__section {
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
  }
  .podcastInsightBlock__description {
    font-size: 28px;
    padding-top: 16px;
  }
  .podcastInsightBlock__infoContainer {
    display: flex;
    column-gap: 68px;
    font-size: 26px;
    line-height: 120%;
    color: #949494;
    margin-top: 33px;
    align-items: baseline;
  }
  .podcastInsightBlock__btn {
    background: url("../../../Media/ArrowRigthDarkRoundCorners.svg") right
      center no-repeat;
    margin-top: 5px;
    color: #949494;
    width: 322px;
    background-size: 41px;
    font-size: 26px;
    line-height: 120%;
    position: relative;
  }
  .podcastInsightBlock__btn:hover {
    color: rgba(69, 69, 69, 1);
  }
}
